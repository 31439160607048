<template>
  <!--大屏简介-->
  <div class="zj_dpIntroduce">
    <div class="zj_inside flexrow">
      <div class="pic wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <video controls="controls">
          <!-- <video controls="controls" autoplay="autoplay"> -->
          <source
            src="../../assets/Images/vedio/vedio_dxfx.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="detail wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/dp.png" />
        </div>
        <div >
          <span class="line2 font14rem fontweight black line4" @click="jumpTo">{{$t("魔数智屏——数据可视化大屏展示")}}</span>
          </div>
        <div class="line3 font8rem black2">
          {{$t("与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。")}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_dpIntroduce",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
    jumpTo(){
      //console.log(132132)
     // window.open('https://mp.weixin.qq.com/s/-iet5E3j4bHUxbFtdTC1_w');
      //https://mp.weixin.qq.com/s/-iet5E3j4bHUxbFtdTC1_w
      this.$router.push({ path: "/detialDesign" });
    }
  },
};
</script>

<style scoped lang="scss">
.zj_dpIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/circle.png");
  background-position: 45% 0%;
  background-repeat: no-repeat;
  background-size: 20%;
  .zj_inside {
    width: 80%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        width: 100%;
        height: 15%;
        img {
          width: 8%;
        }
      }
      .line3 {
        width: 100%;
        height: 70%;
        line-height: 2.5rem;
      }
      .line4{
        cursor:Pointer;
      }
      .line4:hover{
text-decoration:underline;
        color: #2473e3;

}
    }
    .pic {
      width: 60%;
      height: 100%;
      video {
        margin-top: 2%;
        width: 80%;
      }
    }
  }
}
</style>
