<template>
  <!--线上线下一盘货，统一管理货品和库存-->
  <div class="zj_orderRouting">
    <div class="name1 font16rem black flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      {{$t("线上线下一盘货，统一管理货品和库存")}}
    </div>
    <div class="content font10rem black">
      <div class="cd cd1 flexcc wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/qqd/qqd_h_yw.png" />{{$t("业务管理互融一体")}}
      </div>
      <div class="cd cd2 flexcc wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/qqd/qqd_h_pt.png" />{{$t("跨平台+多店铺")}}
      </div>
      <div class="cd cd3 flexcc wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        {{$t("全渠道互通")}}<img src="../../assets/Images/qqd/qqd_h_qd.png" />
      </div>
      <div class="cd cd4 flexcc wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        {{$t("降解库存 提升销售")}}<img src="../../assets/Images/qqd/qqd_h_jj.png" />
      </div>
      <div class="cd cd5">
        <img src="../../assets/Images/qqd/qqd_h_y.png" />
      </div>
      <div class="cdd cd6">
        <img src="../../assets/Images/qqd/qqd_h_d2.png" />
      </div>
      <div class="cdd cd7">
        <img src="../../assets/Images/qqd/qqd_h_d1.png" />
      </div>
      <div class="cdd cd8">
        <img src="../../assets/Images/qqd/qqd_h_rect.png" />
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_orderRouting",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_orderRouting {
  width: 100%;
  height: 100%;
  .name1 {
    width: 80%;
    height: 10%;
    margin: 0 auto;
  }
  @keyframes qqd1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @keyframes qqd2 {
    0% {
      transform: translate(0, 0%);
      opacity: 0;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    90% {
      transform: translate(0%, -90%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -100%);
      opacity: 0;
    }
  }
  @keyframes qqd3 {
    0% {
      transform: translate(0, 0%);
      opacity: 0;
    }
    50% {
      transform: translate(0%, -100%);
      opacity: 1;
    }
    90% {
      transform: translate(0%, -180%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -200%);
      opacity: 0;
    }
  }
  .content {
    width: 80%;
    height: 90%;
    margin: 0 auto;
    background-image: url("../../assets/Images/qqd/qqd_h_center.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 35%;
    .cd {
      width: 20%;
      height: 10%;
      position: relative;
      img {
        width: 20%;
        margin-right: 8%;
      }
    }
    .cd1 {
      margin-left: 14%;
      padding-top: 9%;
      img {
        margin-right: 8%;
      }
    }
    .cd2 {
      margin-left: 16%;
      margin-top: 16%;
      img {
        margin-right: 8%;
      }
    }
    .cd3 {
      margin-left: 66%;
      margin-top: -21.5%;
      img {
        margin-left: 8%;
      }
    }
    .cd4 {
      margin-left: 64%;
      margin-top: 10%;
      img {
        margin-left: 8%;
      }
    }
    .cd5 {
      margin-left: 55%;
      margin-top: -6%;
      animation: qqd1 3s infinite linear;
      -webkit-animation: qqd1 3s infinite linear;
    }
    .cd6 {
      width: 20%;
      height: 10%;
      position: relative;
      z-index: 1;
      margin-left: 48%;
      margin-top: -15%;
      animation: qqd2 3s infinite linear;
      -webkit-animation: qqd2 3s infinite linear;
      img {
        width: 10%;
      }
    }
    .cd7 {
      width: 20%;
      height: 10%;
      position: relative;
      z-index: 1;
      margin-left: 50%;
      margin-top: -4%;
      animation: qqd2 4s infinite linear;
      -webkit-animation: qqd2 4s infinite linear;
      animation-delay: 1s;
      -webkit-animation-delay: 1s;
      opacity: 0;
      img {
        width: 10%;
      }
    }
    .cd8 {
      width: 20%;
      height: 10%;
      position: relative;
      margin-left: 49%;
      margin-top: -6%;
      animation: qqd3 4s infinite linear;
      -webkit-animation: qqd3 4s infinite linear;
      img {
        width: 20%;
      }
    }
  }
}
</style>
