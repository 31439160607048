<template>
  <!--统一微信生态账号体系-->
  <div class="zj_accountSys">
    <div class="name1 font16rem black flextbc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("统一微信生态账号体系")}}</div>
    <div class="name1 font16rem black flextbc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("全渠道多触点获客转化留存")}}</div>
    <div class="lc flexrow">
      <div class="lc_left font8rem wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="left_line flexrow">
          <div class="linedata">
            <div class="datatop flexcc">{{$t("加客户至企微")}}</div>
            <div class="databottom black2">
              <p>{{$t("获客留存")}}</p>
              <p>{{$t("减少流失")}}</p>
            </div>
          </div>
          <div class="linejt flexcc">
            <img src="../../assets/Images/qqd/qqd_jt1.png" />
          </div>
          <div class="linedata">
            <div class="datatop flexcc">{{$t("客户维护")}}</div>
            <div class="databottom black2">
              <p>{{$t("客户标签")}}</p>
              <p>{{$t("一对一服务")}}</p>
            </div>
          </div>
          <div class="linejt flexcc">
            <img src="../../assets/Images/qqd/qqd_jt1.png" />
          </div>
          <div class="linedata">
            <div class="datatop flexcc">{{$t("触达引流")}}</div>
            <div class="databottom black2">
              <p>{{$t("用户画像")}}</p>
              <p>{{$t("营销自动化")}}</p>
            </div>
          </div>
        </div>
        <div class="left_line2 flexrow flexlrend">
          <div class="linejt2 flexcc">
            <img src="../../assets/Images/qqd/qqd_jt3.png" />
          </div>
        </div>
        <div class="left_line flexrow">
          <div class="linedata2">
            <div class="n1">{{$t("批发")}}</div>
            <div class="n2">{{$t("全渠道")}}</div>
            <div class="n3">{{$t("零售")}}</div>
            <div class="n4">{{$t("电商")}}</div>
          </div>
          <div class="linedata">
            <div class="datatop flexcc">{{$t("用户服务")}}</div>
            <div class="databottom black2">
              <p>{{$t("复购提醒")}}</p>
              <p>{{$t("流失回访")}}</p>
              <p>{{$t("售后提醒")}}</p>
            </div>
          </div>
          <div class="linejt flexcc">
            <img src="../../assets/Images/qqd/qqd_jt2.png" />
          </div>
          <div class="linedata">
            <div class="datatop flexcc">{{$t("沟通转化")}}</div>
            <div class="databottom black2">
              <p>{{$t("打标签")}}</p>
              <p>{{$t("看画像1V1")}}</p>
              <p>{{$t("精准营销")}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="lc_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="swiper-container sys_container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in sysData" :key="item.index">
              <div class="right_slide flextbc">
                <div class="s1 box_shadow1">
                  <div class="pic flexcc">
                    <img :src="item.icon" />
                  </div>
                  <div class="name flexcc">{{ item.name }}</div>
                </div>
                <div class="s2 hidden flexcc">
                  <div class="pic2">
                    <img :src="item.pic" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_accountSys",
  data() {
    return {
      sysData: [
        {
          index: 1,
          name: this.$t("精准营销"),
          icon: require("../../assets/Images/qqd/qqd_icon1.png"),
          pic: require("../../assets/Images/qqd/qqd_demo2.png"),
        },
        {
          index: 2,
          name: this.$t("猜Ta喜欢"),
          icon: require("../../assets/Images/qqd/qqd_icon3.png"),
          pic: require("../../assets/Images/qqd/qqd_demo3.png"),
        },
        {
          index: 3,
          name: this.$t("消费记录"),
          icon: require("../../assets/Images/qqd/qqd_icon4.png"),
          pic: require("../../assets/Images/qqd/qqd_demo4.png"),
        },
        {
          index: 4,
          name: this.$t("用户画像"),
          icon: require("../../assets/Images/qqd/qqd_icon2.png"),
          pic: require("../../assets/Images/qqd/qqd_demo1.png"),
        },
        {
          index: 5,
          name: this.$t("扫码购"),
          icon: require("../../assets/Images/qqd/qqd_icon5.png"),
          pic: require("../../assets/Images/qqd/qqd_demo5.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.sysData= [
        {
          index: 1,
          name: this.$t("精准营销"),
          icon: require("../../assets/Images/qqd/qqd_icon1.png"),
          pic: require("../../assets/Images/qqd/qqd_demo2.png"),
        },
        {
          index: 2,
          name: this.$t("猜Ta喜欢"),
          icon: require("../../assets/Images/qqd/qqd_icon3.png"),
          pic: require("../../assets/Images/qqd/qqd_demo3.png"),
        },
        {
          index: 3,
          name: this.$t("消费记录"),
          icon: require("../../assets/Images/qqd/qqd_icon4.png"),
          pic: require("../../assets/Images/qqd/qqd_demo4.png"),
        },
        {
          index: 4,
          name: this.$t("用户画像"),
          icon: require("../../assets/Images/qqd/qqd_icon2.png"),
          pic: require("../../assets/Images/qqd/qqd_demo1.png"),
        },
        {
          index: 5,
          name: this.$t("扫码购"),
          icon: require("../../assets/Images/qqd/qqd_icon5.png"),
          pic: require("../../assets/Images/qqd/qqd_demo5.png"),
        },
      ]
   }
  },
  mounted() {
    this.swiperSys();
    $(".swiper-slide-active .s1").addClass("hidden");
    $(".swiper-slide-active .s2").removeClass("hidden");
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
    swiperSys() {
    $(".swiper-slide-active .s1").addClass("hidden");
    $(".swiper-slide-active .s2").removeClass("hidden");
      let swiper1 = new Swiper(".sys_container", {
        direction: "horizontal",
        autoplay:3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        spaceBetween: 10,
        slidesPerView: "3",
         onSlideChangeStart: function(swiper){
            $(".swiper-slide .s1").removeClass("hidden");
            $(".swiper-slide .s2").addClass("hidden");
            $(".swiper-slide-active .s1").addClass("hidden");
            $(".swiper-slide-active .s2").removeClass("hidden");
         }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_accountSys {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/qqd/qqd_bl.png");
    background-position: 70% 100%;
    background-repeat: no-repeat;
    background-size: 50%;
  .name1 {
    width: 80%;
    height: 6%;
    margin: 0 auto;
  }
  .mar375 {
    margin-left: 37.5%;
  }
  .lc {
    width: 90%;
    height: 88%;
    margin-left: 10%;
    .lc_left {
      width: 40%;
      height: 60%;
      margin-top: 10%;
      .left_line {
        width: 80%;
        height: 30%;
        .linedata {
          width: 25%;
          height: 100%;
          .datatop {
            background-color: rgba(65, 137, 229, 0.1);
            border-radius: 8px;
            width: 100%;
            height: 40%;
          }
          .databottom {
            width: 100%;
            height: 40%;
            margin-top: 5%;
            p {
              text-align: center;
            }
          }
        }
        .linejt {
          width: 12.5%;
          height: 40%;
          img {
            width: 60%;
          }
        }
        .linedata2 {
          width: 37.5%;
          height: 100%;
          background-image: url("../../assets/Images/qqd/qqd_circle.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 50%;
          .n1 {
            position: relative;
            margin-top: 0%;
            text-align: center;
          }
          .n2 {
            position: relative;
            margin-top: 13%;
            text-align: center;
          }
          .n3 {
            position: relative;
            margin-top: 2%;
            margin-left: 20%;
          }
          .n4 {
            position: relative;
            margin-top: -10%;
            margin-left: 62%;
          }
        }
      }
      .left_line2 {
        width: 80%;
        height: 20%;
        .linejt2 {
          width: 25%;
          height: 100%;
          img {
            width: 6%;
          }
        }
      }
    }
    .lc_right {
      width: 60%;
      height: 100%;
      .sys_container {
        width: 100%;
        height: 100%;
        .swiper-slide {
          width: 27%;
        }
      }
      .right_slide {
        width: 100%;
        height: 100%;
        padding-left: 0.5rem;
        .s1 {
          width: 75%;
          height: 32%;
          margin-left: 17.5%;
          background-color: #fff;
          .pic {
            width: 100%;
            height: 70%;
            img {
              width: 50%;
            }
          }
          .name {
            width: 100%;
            height: 30%;
          }
        }
        .s2 {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
