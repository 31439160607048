<template>
  <!--数据驱动业务增长-->
  <div class="zj_businessGrowth">
    <div class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("数据驱动业务增长")}}</div>
    <div class="enname black font10rem flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">Data Driven Business Growth</div>
    <div class="oaad_out">
      <div class="oaad_in1 flexcc wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/bi_pic.png" />
      </div>
      <div class="oaad_pic wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/bi_pic2.png" />
      </div>
      <div class="oaad_in2 flexrow wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="oaad_left">
          <div class="oaad_ys flexrow flextbc marg1">
            <div class="pic flexcc">
              <img :src="businessGrowthData[0].icon" />
            </div>
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[0].name }}
              </div>
              <div class="detail font8rem black2">
                {{ businessGrowthData[0].value }}
              </div>
            </div>
          </div>
          <div class="oaad_ys flexrow flextbc marg2">
            <div class="pic flexcc">
              <img :src="businessGrowthData[1].icon" />
            </div>
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[1].name }}
              </div>
              <div class="detail font8rem black2">
                {{ businessGrowthData[1].value }}
              </div>
            </div>
          </div>
        </div>
        <div class="oaad_right">
          <div class="oaad_ys flexrow flextbc marg3">
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[2].name }}
              </div>
              <div class="detail font8rem black2">
                {{ businessGrowthData[2].value }}
              </div>
            </div>
            <div class="pic flexcc">
              <img :src="businessGrowthData[2].icon" />
            </div>
          </div>
          <div class="oaad_ys flexrow flextbc marg4">
            <div class="content">
              <div class="name font10rem black">
                {{ businessGrowthData[3].name }}
              </div>
              <div class="detail font8rem black2">
                {{ businessGrowthData[3].value }}
              </div>
            </div>
            <div class="pic flexcc">
              <img :src="businessGrowthData[3].icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_businessGrowth",
  data() {
    return {
      businessGrowthData: [
        {
          index: 1,
          name: this.$t("数据的几何级增长"),
          icon: require("../../assets/Images/bi_zz.png"),
          value: "",
        },
        {
          index: 2,
          name: this.$t("数据精益化运营"),
          icon: require("../../assets/Images/bi_zy.png"),
          value: "",
        },
        {
          index: 3,
          name: this.$t("各阶段关注指标"),
          icon: require("../../assets/Images/bi_zb.png"),
          value: "",
        },
        {
          index: 4,
          name: this.$t("新兴的业务增长框架"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("(海盗法则AARRR)"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.businessGrowthData= [
        {
          index: 1,
          name: this.$t("数据的几何级增长"),
          icon: require("../../assets/Images/bi_zz.png"),
          value: "",
        },
        {
          index: 2,
          name: this.$t("数据精益化运营"),
          icon: require("../../assets/Images/bi_zy.png"),
          value: "",
        },
        {
          index: 3,
          name: this.$t("各阶段关注指标"),
          icon: require("../../assets/Images/bi_zb.png"),
          value: "",
        },
        {
          index: 4,
          name: this.$t("新兴的业务增长框架"),
          icon: require("../../assets/Images/bi_kj.png"),
          value: this.$t("(海盗法则AARRR)"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">

.zj_businessGrowth {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  @keyframes bus1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
  }
  .cnname {
    width: 100%;
    height: 10%;
  }
  .enname {
    width: 100%;
    height: 2rem;
  }
  .oaad_out {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    .oaad_in1 {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 40%;
      }
    }
    .oaad_pic {
      width: 10%;
      height: 10%;
      margin-left: 42%;
      margin-top: -19%;
      position: relative;
      animation: bus1 3s infinite linear;
            -webkit-animation: bus1 3s infinite linear;
      img {
        width: 40%;
      }
    }
    .marg1 {
      margin-left: 55%;
      margin-top: 10%;
    }
    .marg2 {
      margin-left: 67%;
      margin-top: 62%;
    }
    .marg3 {
      margin-left: 10%;
      margin-top: 22%;
    }
    .marg4 {
      margin-left: 0%;
      margin-top: 40%;
    }
    .oaad_in2 {
      width: 100%;
      height: 100%;
      position: relative;
      top: -58%;
      .oaad_left {
        width: 33%;
        height: 100%;
        text-align: right;
        .oaad_ys {
          width: 75%;
          // height: 33.3%;
          .pic {
            width: 30%;
            height: 100%;
          }
          .content {
            //width: 70%;
            height: 100%;
            .name {
              width: 100%;
              height: 50%;
            }
            .detail {
              width: 100%;
              height: 50%;
            }
          }
        }
      }
      .oaad_right {
        width: 33%;
        height: 100%;
        text-align: right;
        margin-left: 34%;
        .oaad_ys {
          width: 75%;
          // height: 33.3%;
          .pic {
            width: 30%;
            height: 100%;
          }
          .content {
            //width: 70%;
            height: 100%;
            .name {
              width: 100%;
              height: 50%;
            }
            .detail {
              width: 100%;
              height: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
