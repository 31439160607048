<template>
<!--五大优势-->
  <div class="zj_fiveAdvantages">
    <div class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("五大优势")}}</div>
    <div class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div class="fivead_out">
    <div class="fivead_in">
      <div class="container flexrow">
        <div class="container_part wow"  v-for="item in fiveAdvData" :key="item.index" :class="item.index%2==0?'bounceInUp':'bounceInDown'"
        data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
          <div class="pic flexcc">
            <img :src="item.icon" />
          </div>
          <div class="name flexcc font12rem black">{{item.name}}</div>
          <div class="detail font8rem black2">{{item.detail}}</div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_fiveAdvantages",
  data() {
    return {
      fiveAdvData:[{
        index:1,
        name:this.$t("全移动化场景"),
        icon:require("../../assets/Images/qydhcj.png"),
        detail:this.$t("不受收银台PC硬件束缚，支持iOS和Android移动设备")
      },{
        index:2,
        name:this.$t("多维度分析"),
        icon:require("../../assets/Images/dwdfx.png"),
        detail:this.$t("多维度指标，深层次挖掘数据价值，洞悉问题所在")
      },{
        index:3,
        name:this.$t("智能预警"),
        icon:require("../../assets/Images/znyj.png"),
        detail:this.$t("多种AI预警功能，帮助管理层实时把控门店经营风险")
      },{
        index:4,
        name:this.$t("精细化运营"),
        icon:require("../../assets/Images/jxhyy.png"),
        detail:this.$t("从消费者、购物体验、导购效率等各方面提升门店的数字化运营能力")
      },{
        index:5,
        name:this.$t("功能一体化"),
        icon:require("../../assets/Images/gnyth.png"),
        detail:this.$t("打通数据多端同步，线上线下业务全覆盖，后台统一管理")
      },]
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.fiveAdvData= [{
        index:1,
        name:this.$t("全移动化场景"),
        icon:require("../../assets/Images/qydhcj.png"),
        detail:this.$t("不受收银台PC硬件束缚，支持iOS和Android移动设备")
      },{
        index:2,
        name:this.$t("多维度分析"),
        icon:require("../../assets/Images/dwdfx.png"),
        detail:this.$t("多维度指标，深层次挖掘数据价值，洞悉问题所在")
      },{
        index:3,
        name:this.$t("智能预警"),
        icon:require("../../assets/Images/znyj.png"),
        detail:this.$t("多种AI预警功能，帮助管理层实时把控门店经营风险")
      },{
        index:4,
        name:this.$t("精细化运营"),
        icon:require("../../assets/Images/jxhyy.png"),
        detail:this.$t("从消费者、购物体验、导购效率等各方面提升门店的数字化运营能力")
      },{
        index:5,
        name:this.$t("功能一体化"),
        icon:require("../../assets/Images/gnyth.png"),
        detail:this.$t("打通数据多端同步，线上线下业务全覆盖，后台统一管理")
      },]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
    this.swiperSan();
  },
  methods: {
    swiperSan() {
      let swiper1 = new Swiper(".fivenew_container", {
        direction: "horizontal",
        autoplay: 3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 3,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_fiveAdvantages {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  .cnname {
    width: 100%;
  }
  .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
  .fivead_out {
    width: 100%;
    height: 24rem;
    margin: 0 auto;
    margin-top: 2rem;
    background-color: rgba(247,250,255,0.5);
  .fivead_in {
    width: 100%;
    height: 100%;
  background-image: url("../../assets/Images/linebg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .container{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    .container_part{
      width: 20%;
    height: 100%;
    .pic{
width: 100%;
height: 20%;
margin-top: 30%;
img{
  width: 15%;
}
    }
    .name{
width: 100%;
height: 15%;
    }
    .detail{
width: 70%;
height: 35%;
margin: 0 auto;
text-align: center;
line-height: 1.5rem;
    }
    }
  }
  }
  }
}
</style>
