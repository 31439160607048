<template>
  <!--丰富的数据分析方法-->
  <div class="zj_analyticalMethod">
    <div
      class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t("丰富的数据分析方法")}}
    </div>
    <div
      class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div class="serveSlide">
      <div
        class="adv_part wow bounceInUp"
        v-for="item in productAdv"
        :key="item.index"
        :class="item.index % 3 != 0 ? 'line' : 'line2'"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="name flexcc font12rem black">{{ item.name }}</div>
        <div class="detail font8rem black2">{{ item.detail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_analyticalMethod",
  data() {
    return {
      productAdv: [
        {
          index: 1,
          name: this.$t("钻取"),
          detail: this.$t("向上钻取得到汇总数据向下钻取查看明细数据"),
          icon: require("../../assets/Images/bi_sj1.png"),
        },
        {
          index: 2,
          name: this.$t("联动"),
          detail: this.$t("各个控件间相互关联，数据分析和结果更加明晰"),
          icon: require("../../assets/Images/bi_sj2.png"),
        },
        {
          index: 3,
          name: this.$t("预警"),
          detail: this.$t("针对数据源设置预警阀值，自动向客户端推送预警消息"),
          icon: require("../../assets/Images/bi_sj3.png"),
        },
        {
          index: 4,
          name: this.$t("穿透"),
          detail: this.$t("可基于维度值进行穿透关联分析，便于逻辑查看"),
          icon: require("../../assets/Images/bi_sj4.png"),
        },
        {
          index: 5,
          name: this.$t("切片"),
          detail: this.$t("当维度值很多时，可选择其中一段重点分析"),
          icon: require("../../assets/Images/bi_sj5.png"),
        },
        {
          index: 6,
          name: this.$t("多维度检索"),
          detail: this.$t("全方位，多角度对不同指标进行对比分析"),
          icon: require("../../assets/Images/bi_sj6.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.productAdv= [
        {
          index: 1,
          name: this.$t("钻取"),
          detail: this.$t("向上钻取得到汇总数据向下钻取查看明细数据"),
          icon: require("../../assets/Images/bi_sj1.png"),
        },
        {
          index: 2,
          name: this.$t("联动"),
          detail: this.$t("各个控件间相互关联，数据分析和结果更加明晰"),
          icon: require("../../assets/Images/bi_sj2.png"),
        },
        {
          index: 3,
          name: this.$t("预警"),
          detail: this.$t("针对数据源设置预警阀值，自动向客户端推送预警消息"),
          icon: require("../../assets/Images/bi_sj3.png"),
        },
        {
          index: 4,
          name: this.$t("穿透"),
          detail: this.$t("可基于维度值进行穿透关联分析，便于逻辑查看"),
          icon: require("../../assets/Images/bi_sj4.png"),
        },
        {
          index: 5,
          name: this.$t("切片"),
          detail: this.$t("当维度值很多时，可选择其中一段重点分析"),
          icon: require("../../assets/Images/bi_sj5.png"),
        },
        {
          index: 6,
          name: this.$t("多维度检索"),
          detail: this.$t("全方位，多角度对不同指标进行对比分析"),
          icon: require("../../assets/Images/bi_sj6.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_analyticalMethod {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
  }
  .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 6%;
    .line {
      background-image: url("../../assets/Images/line.png");
      background-position: 100% 38%;
      background-repeat: no-repeat;
      background-size: 0.5% 34%;
    }
    .adv_part {
      width: 33%;
      height: 50%;
      float: left;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 10%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 60%;
        height: 50%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
