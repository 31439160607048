<template>
  <!--应用价值-->
  <div class="zj_applicationValues">
    <div
      class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t("应用价值")}}
    </div>
    <div
      class="nameline flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div class="serveSlide flexrow">
      <div
        class="pic flexcc wow bounceInLeft"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <img src="../../assets/Images/bi/bi_pic2.png" />
      </div>
      <div
        class="content wow bounceInRight"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div
          class="rect_part box_shadow2"
          v-for="item in applicationData"
          :key="item.index"
        >
          <div class="pic flexcc">
            <img :src="item.icon" />
          </div>
          <div class="name flexcc font12rem black">{{ item.name }}</div>
          <div class="detail font8rem black2">{{ item.detail }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_applicationValues",
  data() {
    return {
      applicationData: [
        {
          index: 1,
          name: this.$t("人"),
          detail: this.$t("满足不同的管理角色移动办公、数字化决策需求"),
          icon: require("../../assets/Images/bi_jz1.png"),
        },
        {
          index: 2,
          name: this.$t("货"),
          detail:
            this.$t("商品实时查询分析，实现货品精细化管理，便于商品对门店库存把控"),
          icon: require("../../assets/Images/bi_jz2.png"),
        },
        {
          index: 3,
          name: this.$t("店"),
          detail: this.$t("实时掌握门店运营态势，便于门店标准化管理，提升门店运营能力"),
          icon: require("../../assets/Images/bi_jz3.png"),
        },
        {
          index: 4,
          name: this.$t("场"),
          detail:
            this.$t("实时掌握企业运营态势，提升企业运营管理和风险管控水平，增强工作预见性"),
          icon: require("../../assets/Images/bi_jz4.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.applicationData= [
        {
          index: 1,
          name: this.$t("人"),
          detail: this.$t("满足不同的管理角色移动办公、数字化决策需求"),
          icon: require("../../assets/Images/bi_jz1.png"),
        },
        {
          index: 2,
          name: this.$t("货"),
          detail:
            this.$t("商品实时查询分析，实现货品精细化管理，便于商品对门店库存把控"),
          icon: require("../../assets/Images/bi_jz2.png"),
        },
        {
          index: 3,
          name: this.$t("店"),
          detail: this.$t("实时掌握门店运营态势，便于门店标准化管理，提升门店运营能力"),
          icon: require("../../assets/Images/bi_jz3.png"),
        },
        {
          index: 4,
          name: this.$t("场"),
          detail:
            this.$t("实时掌握企业运营态势，提升企业运营管理和风险管控水平，增强工作预见性"),
          icon: require("../../assets/Images/bi_jz4.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_applicationValues {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
  }
  .nameline {
    width: 100%;
    .line {
      width: 6rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
      margin-top: 1rem;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 6%;
    .pic {
      width: 45%;
      height: 100%;
      img {
        width: 70%;
      }
    }
    .content {
      width: 55%;
      height: 100%;
      .rect_part {
        width: 42%;
        height: 40%;
        margin: 0 8% 8% 0%;
        float: left;
        .pic {
          width: 100%;
          height: 40%;
          img {
            width: 15%;
          }
        }
        .name {
          width: 100%;
          height: 20%;
        }
        .detail {
          width: 80%;
          height: 40%;
          text-align: center;
          margin: 0 auto;
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
