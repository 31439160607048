<template>
  <!--全方位数字化运营优势-->
  <div class="zj_oaAdvantages">
    <div class="cnname flexcc font16rem black wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("全方位数字化运营优势")}}</div>
    <div class="oaad_out">
      <div class="oaad_in1 flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <img src="../../assets/Images/yyys.png" />
      </div>
      <div class="oaad_in2 flexrow">
        <div class="oaad_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
          <div class="oaad_ys marl25">
            <div class="font12rem black oaadname1">{{ fiveAdvData[0].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[0].detail[0].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[0].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[0].detail[0].value2 }}
              </div>
            </div>
          </div>
          <div class="oaad_ys marl15">
            <div class="font12rem black oaadname2">{{ fiveAdvData[1].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[0].name }}
              </div>
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[0].value2 }}
              </div>
            </div>

            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[1].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[1].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[1].value2 }}
              </div>
            </div>

            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[2].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[2].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[2].value2 }}
              </div>
            </div>

            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[3].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[3].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[3].value2 }}
              </div>
            </div>

            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[1].detail[4].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[4].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[1].detail[4].value2 }}
              </div>
            </div>
          </div>
          <div class="oaad_ys marl20">
            <div class="font12rem black oaadname3">{{ fiveAdvData[2].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[2].detail[0].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[2].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[2].detail[0].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[2].detail[1].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[2].detail[1].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[2].detail[1].value2 }}
              </div>
            </div>
          </div>
        </div>
        <div class="oaad_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
          <div class="oaad_ys">
            <div class="font12rem black oaadname1">{{ fiveAdvData[3].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[3].detail[0].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[0].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[3].detail[1].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[1].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[1].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[3].detail[2].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[2].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[2].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[3].detail[3].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[3].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[3].detail[3].value2 }}
              </div>
            </div>
          </div>
          <div class="oaad_ys marl10">
            <div class="font12rem black oaadname2">{{ fiveAdvData[4].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[4].detail[0].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[0].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[4].detail[1].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[1].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[1].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[4].detail[2].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[2].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[4].detail[2].value2 }}
              </div>
            </div>
          </div>
          <div class="oaad_ys marl5">
            <div class="font12rem black oaadname3">{{ fiveAdvData[5].name }}</div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[5].detail[0].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[5].detail[0].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[5].detail[0].value2 }}
              </div>
            </div>
            <div class="">
              <div class="font8rem black">
                {{ fiveAdvData[5].detail[1].name }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[5].detail[1].value }}
              </div>
              <div class="font8rem black2">
                {{ fiveAdvData[5].detail[1].value2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_oaAdvantages",
  data() {
    return {
      fiveAdvData: [
        {
          index: 1,
          name: this.$t("快速化"),
          detail: [
            {
              name: this.$t("不受收银台PC硬件束缚，支持iOS和Android移动设备"),
              value:this.$t("特有的去中心化计算，最大程度的利用了本地计算富余的计算能，节约了成本"),
              value2: "",
            },
          ],
        },
        {
          index: 2,
          name: this.$t("一体化"),
          detail: [
            {
              name: this.$t("整合五种形式POS，行业内独一性"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("扩展性强、支持多种接口"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持多语言、多币种"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持多种收银模式，如现金、支付宝、微信支付等"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持离线模式，安卓、苹果、小程序等多种模式"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 3,
          name: this.$t("移动化"),
          detail: [
            {
              name: this.$t("在线POS支持手机支付、小程序扫码购进行营销下单"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("接通微信支付，扫码购直接联网后台"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 4,
          name: this.$t("便捷化"),
          detail: [
            {
              name: this.$t("安装便捷"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("操作便捷"),
              value: this.$t("操作页面秉承“三步并一步”理念，简单明了，操作简捷"),
              value2: "",
            },
            {
              name: this.$t("参数自定义"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("千店千面、操作页面自定义"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 5,
          name: this.$t("业务化"),
           detail: [
            {
              name: this.$t("智能营销推荐"),
              value: this.$t("自动计算最优促销，给客户推荐最优促销，提供最佳的购物体验"),
              value2: "",
            },
            {
              name: this.$t("商场结算管理"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("商品及库存管控"),
              value: this.$t("库存管控精细化（负库存管控严谨）"),
              value2: "",
            }
          ],
        },
        {
          index: 6,
          name: this.$t("场景化"),
          detail: [
            {
              name: this.$t("行业首创最优促销，可实现多种促销和卡券重合"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("促销功能多样化且具有创新性，支持市面上99%促销形式，活动和活动之间可叠加"),
              value: "",
              value2: "",
            }
          ],
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.fiveAdvData= [
        {
          index: 1,
          name: this.$t("快速化"),
          detail: [
            {
              name: this.$t("不受收银台PC硬件束缚，支持iOS和Android移动设备"),
              value:this.$t("特有的去中心化计算，最大程度的利用了本地计算富余的计算能，节约了成本"),
              value2: "",
            },
          ],
        },
        {
          index: 2,
          name: this.$t("一体化"),
          detail: [
            {
              name: this.$t("整合五种形式POS，行业内独一性"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("扩展性强、支持多种接口"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持多语言、多币种"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持多种收银模式，如现金、支付宝、微信支付等"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("支持离线模式，安卓、苹果、小程序等多种模式"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 3,
          name: this.$t("移动化"),
          detail: [
            {
              name: this.$t("在线POS支持手机支付、小程序扫码购进行营销下单"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("接通微信支付，扫码购直接联网后台"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 4,
          name: this.$t("便捷化"),
          detail: [
            {
              name: this.$t("安装便捷"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("操作便捷"),
              value: this.$t("操作页面秉承“三步并一步”理念，简单明了，操作简捷"),
              value2: "",
            },
            {
              name: this.$t("参数自定义"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("千店千面、操作页面自定义"),
              value: "",
              value2: "",
            },
          ],
        },
        {
          index: 5,
          name: this.$t("业务化"),
           detail: [
            {
              name: this.$t("智能营销推荐"),
              value: this.$t("自动计算最优促销，给客户推荐最优促销，提供最佳的购物体验"),
              value2: "",
            },
            {
              name: this.$t("商场结算管理"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("商品及库存管控"),
              value: this.$t("库存管控精细化（负库存管控严谨）"),
              value2: "",
            }
          ],
        },
        {
          index: 6,
          name: this.$t("场景化"),
          detail: [
            {
              name: this.$t("行业首创最优促销，可实现多种促销和卡券重合"),
              value: "",
              value2: "",
            },
            {
              name: this.$t("促销功能多样化且具有创新性，支持市面上99%促销形式，活动和活动之间可叠加"),
              value: "",
              value2: "",
            }
          ],
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
    swiperSan() {
      let swiper1 = new Swiper(".fivenew_container", {
        direction: "horizontal",
        autoplay: 3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 3,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_oaAdvantages {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  .cnname {
    width: 100%;
    height: 10%;
  }
  .oaad_out {
    width: 100%;
    height: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    .oaad_in1 {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        width: 40%;
      }
    }
    
        .marl25{
          margin-left: 25%;
        }
        .marl20{
          margin-left: 20%;
        }
        .marl15{
          margin-left: 15%;
        }
        .marl10{
          margin-left: 10%;
        }
        .marl5{
          margin-left: 5%;
        }
    .oaad_in2 {
      width: 100%;
      height: 100%;
      position: relative;
      top: -100%;
      .oaad_left {
        width: 33%;
        height: 100%;
        text-align: right;
        .oaad_ys {
          width: 75%;
         // height: 33.3%;
          .oaadname1{
            margin-top: 15%;
          }
          .oaadname2{
            margin-top: 15%;
          }
          .oaadname3{
            margin-top: 13%;
          }
        }
      }
      .oaad_right {
        width: 33%;
        height: 100%;
        text-align: left;
        margin-left: 34%;
        .oaad_ys {
          width: 75%;
         // height: 33.3%;
          .oaadname1{
            margin-top: 10%;
          }
          .oaadname2{
            margin-top: 16%;
          }
          .oaadname3{
            margin-top: 10%;
          }
        }
      }
    }
  }
}
</style>
