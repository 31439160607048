<template>
  <!--产品展示tab-->
  <div class="protop">
    <div class="tip_meun">
      <div class="weui_tab flexcc">
        <div class="ui_tab_area flexrow font10rem">
          <div class="ui_tab ui_tabreport flexrow">
            <div
              class="ui_tab_item bounceIn"
              :class="{ ui_tab_item_on: item.id == selected }"
              v-for="item in timeData"
              :key="item.id"
              @click="toFather(item.id)"
              data-wow-duration="2s"
              data-wow-delay="0s"
              data-wow-offset="10"
              data-wow-iteration="1"
            >
              <span>{{ item.name }}</span>
            </div>

            <!-- <div class="ui_tab_item ui_tab_item_on" typeId="home" @click="tabContentSel('home',this)"><span>首页</span></div>
                            <div class="ui_tab_item" typeId="about" @click="tabContentSel('about',this)"><span>关于我们</span></div>
                            <div class="ui_tab_item" typeId="product" @click="tabContentSel('product',this)"><span>产品展示</span></div>
                            <div class="ui_tab_item" typeId="custom" @click="tabContentSel('custom',this)"><span>客户案例</span></div>
                            <div class="ui_tab_item" typeId="contact" @click="tabContentSel('contact',this)"><span>联系我们</span></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "protop",
  props: ["timeData", "index"],
  data() {
    return {
      selected: 0,
      isFixed: true,
      pagename: "productPass",
    };
  },
  mounted() {
    this.selected = localStorage.getItem("toId");
    new this.$wow.WOW({ live: false }).init();
    this.changeTab();

    let topClick = localStorage.getItem("topClick");
    if (topClick == "Y") {
      $(".ui_tab_item").addClass("wow");
    } else {
      $(".ui_tab_item").removeClass("wow");
    }
    //  window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  watch: {
    timeData(newval, oldval) {
      // console.log("newval", newval);
      // console.log("oldval", oldval);
      //  $(".ui_tab_item:first-child ").removeClass("ui_tab_item_on");
      // this.selected = newval;
    },
    index(newval, oldval) {
      // console.log("newval", newval);
      // console.log("oldval", oldval);
      //  $(".ui_tab_item:first-child ").removeClass("ui_tab_item_on");
      this.selected = this.index;
    },
  },
  methods: {
    tabContentSel(id, e) {
      console.log(id);
      $(e).addClass("ui_tab_item_on").siblings().removeClass("ui_tab_item_on");
    },
    toFather(time) {
      console.log(time);
      this.$emit("fatherMethod", time);
      this.selected = time;

      localStorage.setItem("topClick", "N");
      // var url = "../../"+time;
      // window.open(url, '_self');
      if (time == 0) {
        this.pagename = "productPass";
      } else if (time == 1) {
        this.pagename = "productRetail";
      } else if (time == 2) {
        this.pagename = "productPos";
      } else if (time == 3) {
        this.pagename = "productDashboard";
      } else if (time == 4) {
        this.pagename = "productBi";
      } else {
        this.pagename = "productMarket";
      }
      this.$router.push(this.pagename);
    },
    changeTab() {
      $(".ui_tab_item").click(function () {
        $(this)
          .addClass("ui_tab_item_on")
          .siblings()
          .removeClass("ui_tab_item_on");
      });
    },
  },
};
</script>

<style scoped lang="scss">
.protop {
  width: 100%;
  height: 100%;
  .tip_meun {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    .weui_tab {
      width: 100%;
      height: 100%;
    }
    .ui_tab_area {
      width: 100%;
      height: 100%;
    }
    .ui_tabreport {
      width: 100%;
      height: 100%;
    }
    .ui_tab_item {
      position: relative;
      display: block;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      padding: 4rem 0 0 0;
      text-align: center;
      color: #333333;
      -webkit-tap-highlight-color: transparent;
      cursor: pointer;
    }
    .ui_tab_item_on span {
      color: #2473e3;
      padding-bottom: 3px;
      font-size: 1.2rem;
      font-weight: bold;
      border-bottom: 0.2rem solid #2473e3;
    }
  }
}
</style>
