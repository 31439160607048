<template>
  <!--DRP-->
  <div class="zj_drp flexrow" v-if="drpData">
    <div class="drp_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="bj box_shadow1"></div>
        <div class="pic">
          <img :src="drpData.drp_pic" />
        </div>
        <div class="name font10rem">{{drpData.name}}</div>
      </div>
      <div class="drp_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="name flextbc black font10rem">
          <img :src="drpData.icon1" />{{drpData.id}}
        </div>
        <div class="detail font8rem black2">
          <p>{{drpData.value1}}</p>
          <p>{{drpData.value2}}</p>
          <p>{{drpData.value3}}</p>
        </div>
      </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_drp",
  props:["drpData"],
  data() {
    return {
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_drp {
  width: 100%;
  height: 100%;
   .drp_left {
      width: 50%;
      height: 100%;
      .bj {
        width: 60%;
        height: 45%;
        position: relative;
        margin-top: 25%;
        margin-left: 10%;
      }
      .pic {
        width: 50%;
        height: 45%;
        position: relative;
        margin-top: -45%;
        margin-left: 15%;
        img {
          width: 100%;
        }
      }
      .name {
        width: 80%;
        height: 10%;
        text-align: center;
        position: relative;
      }
    }
    .drp_right {
      width: 50%;
      height: 100%;
      .name {
        width: 100%;
        height: 15%;
        margin-top: 15%;
        img{
          width: 5%;
          margin-right: 2%;
        }
      }
      .detail {
        width: 80%;
        height: 50%;
        line-height: 2.5rem;
      }
    }
}
</style>
