<template>
  <!--新零售八大亮点-->
  <div class="zj_xlsPoints">
    <div class="cnname font16rem black flexcc wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("新零售八大亮点")}}</div>
    <div class="serveSlide">
      <div class="adv_part wow bounceInUp" v-for="item in xlsPoints" :key="item.index" :class="item.index%4!=0?'line':'line2'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="name flexcc font12rem black">{{item.name}}</div>
        <div class="detail font8rem black2">{{item.detail}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_xlsPoints",
  data() {
    return {
      xlsPoints: [
        {
          index: 1,
          name: this.$t("全渠道"),
          detail:
            this.$t("支持门店、电商、第三方平台等多种销售渠道，线上线下全线贯通"),
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: this.$t("一体化"),
          detail:
            this.$t("一个数据中心，一体化管理，打通线上和线下，跨店跨区统一运营"),
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: this.$t("大数据"),
          detail:
           this.$t("所有门店、所有渠道数据实时同步，让企业掌握一手数据，实现大数据分析"),
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: this.$t("强管控"),
          detail:
            this.$t("数据归集至总部，集团式企业管理，实现对店、对人、对用户的全面掌控"),
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        },
        {
          index: 5,
          name: this.$t("高效管理"),
          detail:
            this.$t("智能补货、OMS自动分单、多方配送等智能化管理方式，让管理更高效"),
          icon: require("../../assets/Images/xls/xls_ld5.png"),
        },
        {
          index: 6,
          name: this.$t("精准营销"),
          detail:
            this.$t("三合一会员卡、二维码营销、LBS定位推荐等精准营销方式，快速吸粉"),
          icon: require("../../assets/Images/xls/xls_ld6.png"),
        },
        {
          index: 7,
          name: this.$t("智慧销售"),
          detail: this.$t("不同门店销售不同商品，每个门店都能独立打造爆款，线上线下同步销售"),
          icon: require("../../assets/Images/xls/xls_ld7.png"),
        },
        {
          index: 8,
          name: this.$t("灵活运营"),
          detail:
            this.$t("总部可统一运营，门店也能自己开展促销活动，支持送货上门、自提等多种配送方式"),
          icon: require("../../assets/Images/xls/xls_ld8.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.xlsPoints= [
        {
          index: 1,
          name: this.$t("全渠道"),
          detail:
            this.$t("支持门店、电商、第三方平台等多种销售渠道，线上线下全线贯通"),
          icon: require("../../assets/Images/xls/xls_ld1.png"),
        },
        {
          index: 2,
          name: this.$t("一体化"),
          detail:
            this.$t("一个数据中心，一体化管理，打通线上和线下，跨店跨区统一运营"),
          icon: require("../../assets/Images/xls/xls_ld2.png"),
        },
        {
          index: 3,
          name: this.$t("大数据"),
          detail:
           this.$t("所有门店、所有渠道数据实时同步，让企业掌握一手数据，实现大数据分析"),
          icon: require("../../assets/Images/xls/xls_ld3.png"),
        },
        {
          index: 4,
          name: this.$t("强管控"),
          detail:
            this.$t("数据归集至总部，集团式企业管理，实现对店、对人、对用户的全面掌控"),
          icon: require("../../assets/Images/xls/xls_ld4.png"),
        },
        {
          index: 5,
          name: this.$t("高效管理"),
          detail:
            this.$t("智能补货、OMS自动分单、多方配送等智能化管理方式，让管理更高效"),
          icon: require("../../assets/Images/xls/xls_ld5.png"),
        },
        {
          index: 6,
          name: this.$t("精准营销"),
          detail:
            this.$t("三合一会员卡、二维码营销、LBS定位推荐等精准营销方式，快速吸粉"),
          icon: require("../../assets/Images/xls/xls_ld6.png"),
        },
        {
          index: 7,
          name: this.$t("智慧销售"),
          detail: this.$t("不同门店销售不同商品，每个门店都能独立打造爆款，线上线下同步销售"),
          icon: require("../../assets/Images/xls/xls_ld7.png"),
        },
        {
          index: 8,
          name: this.$t("灵活运营"),
          detail:
            this.$t("总部可统一运营，门店也能自己开展促销活动，支持送货上门、自提等多种配送方式"),
          icon: require("../../assets/Images/xls/xls_ld8.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.zj_xlsPoints {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 6%;
    .line{
      background-image: url("../../assets/Images/line.png");
        background-position: 100% 38%;
    background-repeat: no-repeat;
    background-size: 0.7% 30%;
    }
    .adv_part {
      width: 25%;
      height: 50%;
      float: left;
      .pic {
        width: 100%;
        //height: 30%;
        img{
          width: 13%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        height: 50%;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
</style>
