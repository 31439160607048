<template>
  <!--新零售一体化系统-->
  <div class="zj_xlsAdvSol">
    <div class="cnname flexcc font16rem black wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("新零售一体化系统")}}</div>
    <div class="enname black font10rem flexcc wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      {{$t("技术、产品、服务全面升级，重构新零售")}}
    </div>
    <div class="content flexrow flexlraround">
      <div class="td wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div
          class="line flextbc flexlrend"
          v-for="item in tdData"
          :key="item.index"
        >
          <span class="black2 font8rem">{{ item.detail }}</span>
          <span class="circle font30rem marl5 circle1"></span>
        </div>
      </div>
      <div class="cx wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div
          class="line flextbc flexlrstart"
          v-for="item in cxData"
          :key="item.index"
        >
          <span class="circle font30rem marr5 circle2"></span>
          <span class="black2 font8rem">{{ item.detail }}</span>
        </div>
      </div>
    </div>
    <div class="tdname flexrow flextbc flexlraround font12rem wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="black">{{$t("痛点")}}</div>
      <div class="white">{{$t("成效")}}</div>

    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_xlsAdvSol",
  data() {
    return {
      tdData: [
        {
          index: 1,
          detail: this.$t("开发速度慢"),
        },
        {
          index: 2,
          detail: this.$t("投入高"),
        },
        {
          index: 3,
          detail: this.$t("管理软件众多"),
        },
        {
          index: 4,
          detail: this.$t("数据不互通"),
        },
        {
          index: 5,
          detail: this.$t("移动化水平低"),
        },
        {
          index: 6,
          detail: this.$t("操作复杂"),
        },
        {
          index: 7,
          detail: this.$t("可拓展性差"),
        },
      ],
      cxData: [
        {
          index: 1,
          detail: this.$t("10倍开发效率"),
        },
        {
          index: 2,
          detail: this.$t("节约50%前期投入"),
        },
        {
          index: 3,
          detail: this.$t("功能一体化"),
        },
        {
          index: 4,
          detail: this.$t("数据互通"),
        },
        {
          index: 5,
          detail: this.$t("移动化水平高"),
        },
        {
          index: 6,
          detail: this.$t("三步并一步，操作简单"),
        },
        {
          index: 7,
          detail: this.$t("支持嵌套二次开发，满足企业成长需求"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.tdData= [
        {
          index: 1,
          detail: this.$t("开发速度慢"),
        },
        {
          index: 2,
          detail: this.$t("投入高"),
        },
        {
          index: 3,
          detail: this.$t("管理软件众多"),
        },
        {
          index: 4,
          detail: this.$t("数据不互通"),
        },
        {
          index: 5,
          detail: this.$t("移动化水平低"),
        },
        {
          index: 6,
          detail: this.$t("操作复杂"),
        },
        {
          index: 7,
          detail: this.$t("可拓展性差"),
        },
      ]
      this.cxData = [
        {
          index: 1,
          detail: this.$t("10倍开发效率"),
        },
        {
          index: 2,
          detail: this.$t("节约50%前期投入"),
        },
        {
          index: 3,
          detail: this.$t("功能一体化"),
        },
        {
          index: 4,
          detail: this.$t("数据互通"),
        },
        {
          index: 5,
          detail: this.$t("移动化水平高"),
        },
        {
          index: 6,
          detail: this.$t("三步并一步，操作简单"),
        },
        {
          index: 7,
          detail: this.$t("支持嵌套二次开发，满足企业成长需求"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_xlsAdvSol {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  .cnname {
    width: 100%;
    height: 10%;
  }
  .enname {
    width: 100%;
    height: 2rem;
    margin-bottom: 4rem;
  }
  .marl5{
    margin-left: 5%;
  }
  .marr5{
    margin-right: 5%;
  }
  .content {
    width: 80%;
    //  height: 85%;
    margin: 0 auto;
    .circle{
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100px;
    }
    .circle1{
      background-color:#e1e6f0;
    }
    .circle2{
      background-color:#2a85f2;
    }
    .td {
      width: 40%;
      border: 1px solid #e1e6f0;
      .line {
        //width: 100%;
        line-height: 3rem;
        padding: 0 8rem 0 4rem;
      }
    }
    .cx {
      width: 40%;
      border: 1px solid #e1e6f0;
      .line {
        //width: 100%;
        line-height: 3rem;
       padding: 0 4em 0 8rem;
      }
    }
  }
  .tdname{
    width: 18%;
    height: 18%;
    position: relative;
    background-image: url("../../assets/Images/xls/xls_jt.png");
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top:-13% ;
    margin-left:41% ;
    div{
      width: 40%;
      text-align: center;
    }
  }
}
</style>
