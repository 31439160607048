<template>
  <!--应用场景-->
  <div class="zj_Scenario">
    <div class="cnname font16rem black wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("应用场景")}}</div>
    <div class="nameline wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div class="serveSlide wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="swiper-container scenario_container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in scenarioData" :key="item.index">
         <div class="adv_part">
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
         <div class="name font8rem">
          {{item.name}}
        </div>
      </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_Scenario",
  data() {
    return {
      scenarioData: [
        {
          index: 1,
          name:this.$t("战略指挥中心"),
          icon: require("../../assets/Images/dashboard/zhzx.png"),
        },
        {
          index: 2,
          name:this.$t("集团会议中心"),
          icon: require("../../assets/Images/dashboard/jthy.png"),
        },
        {
          index: 3,
          name:this.$t("实时监控中心"),
          icon: require("../../assets/Images/dashboard/jkzx.png"),
        }
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.scenarioData= [
        {
          index: 1,
          name:this.$t("战略指挥中心"),
          icon: require("../../assets/Images/dashboard/zhzx.png"),
        },
        {
          index: 2,
          name:this.$t("集团会议中心"),
          icon: require("../../assets/Images/dashboard/jthy.png"),
        },
        {
          index: 3,
          name:this.$t("实时监控中心"),
          icon: require("../../assets/Images/dashboard/jkzx.png"),
        }
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
    this.swiperScenario();
  },
  methods: {
    swiperScenario() {
      let swiper1 = new Swiper(".scenario_container", {
        direction: "horizontal",
        autoplay: 3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        //centeredSlides: true,
        spaceBetween: 30,
        slidesPerView: "auto"
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_Scenario {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 6%;
    .scenario_container{
    width: 100%;
    height: 100%;
    .swiper-slide{
      width: 40%;
    }
    }
    .adv_part {
      width: 100%;
      height: 50%;
      float: left;
      .pic {
        width: 100%;
        //height: 30%;
        img{
          width: 100%;
        }
      }
      .name{
        width: 100%;
      height: 10%;
      line-height: 2.5rem;
      }
    }
  }
}
</style>
