<template>
  <!--MDM-->
  <div class="zj_mdm flexrow" v-if="proData">
    <div class="drp_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="mdmpic1">
        <img src="../../assets/Images/xls/mdm_zhuan.png" />
      </div>
      <div class="mdmpic2">
        <p class="flexcc font10rem">{{$t("人")}}</p>
        <img src="../../assets/Images/xls/mdm_r.png" />
      </div>
      <div class="mdmpic3">
        <p class="flexcc font10rem">{{$t("公司")}}</p>
        <img src="../../assets/Images/xls/mdm_gs.png" />
      </div>
      <div class="mdmpic4">
        <p class="flexcc font10rem">{{$t("货")}}</p>
        <img src="../../assets/Images/xls/mdm_h.png" />
      </div>
      <div class="mdmpic5">
        <p class="flexcc font10rem">{{$t("店")}}</p>
        <img src="../../assets/Images/xls/mdm_dp.png" />
      </div>
    </div>
    <div class="drp_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="name flextbc black font10rem">
        <img :src="proData.icon1" />{{ proData.id }}
      </div>
      <div class="detail font8rem black2">
        <p>{{ proData.value1 }}</p>
        <p>{{ proData.value2 }}</p>
        <p>{{ proData.value3 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_mdm",
  props: ["proData"],
  data() {
    return {};
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_mdm {
  width: 100%;
  height: 100%;
  @keyframes mdm1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @keyframes mdm2 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -20%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @keyframes mdm3 {
    0% {
      transform: translate(0, -20%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -20%);
      opacity: 1;
    }
  }
  .drp_left {
    width: 50%;
    height: 100%;
    background-image: url("../../assets/Images/xls/mdm_pic.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 0 90%;
    .mdmpic1 {
      width: 10%;
      //height: 10%;
      margin-left: 37%;
      margin-top: 23%;
      animation: mdm1 4s infinite linear;
      -webkit-animation: mdm1 4s infinite linear;
      img {
        width: 100%;
      }
    }
    .mdmpic2 {
      width: 8%;
      //height: 10%;
      margin-left: 9%;
      margin-top: -31%;
      line-height: 3.5rem;

      img {
        width: 100%;
      animation: mdm3 3s infinite linear;
      -webkit-animation: mdm3 3s infinite linear;
      }
    }
    .mdmpic3 {
      width: 8%;
      //height: 10%;
      margin-left: 2%;
      margin-top: 12%;
      line-height: 3.5rem;

      img {
        width: 100%;
      animation: mdm2 3s infinite linear;
      -webkit-animation: mdm2 3s infinite linear;
      }
    }
    .mdmpic4 {
      width: 8%;
      //height: 10%;
      margin-left: 70%;
      margin-top: -45%;
      line-height: 3.5rem;

      img {
        width: 100%;
      animation: mdm2 3s infinite linear;
      -webkit-animation: mdm2 3s infinite linear;
      }
    }
    .mdmpic5 {
      width: 8%;
      //height: 10%;
      margin-left: 68%;
      margin-top: 6%;
      line-height: 3.5rem;

      img {
        width: 100%;
      animation: mdm3 3s infinite linear;
      -webkit-animation: mdm3 3s infinite linear;
      }
    }
  }
  .drp_right {
    width: 50%;
    height: 100%;
    .name {
      width: 100%;
      height: 15%;
      margin-top: 15%;
      img {
        width: 5%;
        margin-right: 2%;
      }
    }
    .detail {
      width: 80%;
      height: 50%;
      line-height: 2.5rem;
    }
  }
}
</style>
