<template>
  <!--全渠道订单路由-->
  <div class="zj_orderRouting">
    <div class="name1 font14rem black flextbc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("全渠道订单路由")}}</div>
    <div class="name2 flextbc black2 font8rem wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("打通全渠道 布局新零售")}}</div>
    <div class="lc flexrow">
      <div class="lc_left font10rem wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div :class="$i18n.locale == 'CN'?'ly1 black':'ly1EN black'">{{$t("会员通")}}</div>
        <div :class="$i18n.locale == 'CN'?'ly2 black':'ly2EN black'">{{$t("商品通")}}</div>
        <div :class="$i18n.locale == 'CN'?'ly3 black':'ly3EN black'">{{$t("服务通")}}</div>
        <div :class="$i18n.locale == 'CN'?'ly4 black':'ly4EN black'">{{$t("全渠道")}}</div>
        <div class="lyjt white">
          <img src="../../assets/Images/qqd/qqd_top/qqd_ddjt.png" />
        </div>
      </div>
      <div class="lc_center font8rem wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="1s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="ly1 flexrow flexlraround">
          <div
            class="lydata"
            v-for="item in routedata"
            :key="item.index"
            v-if="item.index < 5"
          >
            <div class="name flexcc">{{ item.name }}</div>
            <div class="pic flexcc">
              <img src="../../assets/Images/qqd/qqd_top/qqd_ddk1.png" />
            </div>
          </div>
        </div>
        <div class="ly2 ly21"></div>
        <div class="ly3 flexrow">
          <div class="ly3_detail ly3_detail1">
            <div class="name">{{ fourdata[0] }}</div>
            <div class="ly3pic1">
              <img src="../../assets/Images/qqd/qqd_top/qqd_hpticon.png" />
            </div>
            <div class="ly3pic2">
              <img src="../../assets/Images/qqd/qqd_top/qqd_hpticon.png" />
            </div>
            <div class="ly3pic3">
              <img src="../../assets/Images/qqd/qqd_top/qqd_hpticon.png" />
            </div>
          </div>
          <div class="ly3_detail ly3_detail2">
            <div class="name">{{ fourdata[1] }}</div>
          </div>
          <div class="ly3_detail ly3_detail3">
            <div class="name">{{ fourdata[2] }}</div>
            <div class="ly3pic0">
              <img src="../../assets/Images/qqd/qqd_top/qqd_zdt.png" />
            </div>
            <div class="ly3pic1">
              <img src="../../assets/Images/qqd/qqd_top/qqd_zdt1.png" />
            </div>
            <div class="ly3pic2">
              <img src="../../assets/Images/qqd/qqd_top/qqd_zdt2.png" />
            </div>
          </div>
          <div class="ly3_detail ly3_detail4">
            <div class="name">{{ fourdata[3] }}</div>
            <div class="ly3pic1">
              <img src="../../assets/Images/qqd/qqd_top/qqd_cwt1.png" />
            </div>
            <div class="ly3pic2">
              <img src="../../assets/Images/qqd/qqd_top/qqd_cwt1.png" />
            </div>
            <div class="ly3pic3">
              <img src="../../assets/Images/qqd/qqd_top/qqd_cwt1.png" />
            </div>
          </div>
        </div>
        <div class="ly2 ly22"></div>
        <div class="ly1 flexrow flexlraround">
          <div
            class="lydata"
            v-for="item in routedata"
            :key="item.index"
            v-if="item.index > 4"
          >
            <div class="pic flexcc">
              <img src="../../assets/Images/qqd/qqd_top/qqd_ddk2.png" />
            </div>
            <div class="name flexcc">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="lc_right font10rem wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="2s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="ly1 white">
          <p class="onoffline">{{$t("线上线下")}}</p>
          <p>{{$t("一体化")}}</p>
        </div>
        <div class="ly2 black">
          <img src="../../assets/Images/qqd/qqd_top/qqd_dddot.png" />{{$t("电商")}}
        </div>
        <div class="ly3 black">
          <img src="../../assets/Images/qqd/qqd_top/qqd_dddot.png" />{{$t("实体")}}
        </div>
        <div class="ly4 black">
          <img src="../../assets/Images/qqd/qqd_top/qqd_dddot.png" />{{$t("渠道")}}
        </div>
        <div class="lyjt white">
          <img src="../../assets/Images/qqd/qqd_top/qqd_ddjt.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_orderRouting",
  data() {
    return {
      routedata: [
        {
          index: 1,
          name: this.$t("货品融合"),
        },
        {
          index: 2,
          name: this.$t("交易融合"),
        },
        {
          index: 3,
          name: this.$t("渠道融合"),
        },
        {
          index: 4,
          name: this.$t("体验融合"),
        },
        {
          index: 5,
          name: this.$t("同一盘货"),
        },
        {
          index: 6,
          name: this.$t("同一订单"),
        },
        {
          index: 7,
          name: this.$t("同一渠道"),
        },
        {
          index: 8,
          name: this.$t("同一支付"),
        },
      ],
      fourdata: [this.$t("货品通"), this.$t("订单通"), this.$t("终端通"), this.$t("财务通")],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.routedata= [
        {
          index: 1,
          name: this.$t("货品融合"),
        },
        {
          index: 2,
          name: this.$t("交易融合"),
        },
        {
          index: 3,
          name: this.$t("渠道融合"),
        },
        {
          index: 4,
          name: this.$t("体验融合"),
        },
        {
          index: 5,
          name: this.$t("同一盘货"),
        },
        {
          index: 6,
          name: this.$t("同一订单"),
        },
        {
          index: 7,
          name: this.$t("同一渠道"),
        },
        {
          index: 8,
          name: this.$t("同一支付"),
        },
      ]
      this.fourdata = [this.$t("货品通"), this.$t("订单通"), this.$t("终端通"), this.$t("财务通")]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_orderRouting {
  width: 100%;
  height: 100%;
  .name1 {
    width: 70%;
    height: 10%;
    margin: 0 auto;
  }
  .name2 {
    width: 70%;
    height: 5%;
    margin: 0 auto;
  }
  .lc {
    width: 70%;
    height: 85%;
    margin: 0 auto;
    .lc_left {
      width: 25%;
      height: 100%;
      background-image: url("../../assets/Images/qqd/qqd_top/qqd_ddleft.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 80%;
      .ly1 {
        position: relative;
        margin-left: 33%;
        margin-top: 68%;
      }
      .ly1EN {
        position: relative;
        margin-left: 30%;
        margin-top: 68%;
      }
      .ly2 {
        position: relative;
        margin-left: 19%;
        margin-top: 18%;
      }
      .ly2EN {
        position: relative;
        margin-left: 10%;
        margin-top: 18%;
      }
      .ly3 {
        position: relative;
        margin-left: 29%;
        margin-top: 19%;
      }
      .ly3EN {
        position: relative;
        margin-left: 29%;
        margin-top: 19%;
      }
      .ly4 {
        position: relative;
        margin-left: 57%;
        margin-top: -32%;
      }
      .ly4EN {
        position: relative;
        margin-left: 47%;
        margin-top: -32%;
      }
      .lyjt {
        width: 60%;
        height: 20%;
        position: relative;
        margin-left: 90%;
        margin-top: -9%;
        img {
          width: 50%;
        }
      }
    }
    .lc_center {
      width: 50%;
      height: 100%;
      .ly1 {
        width: 80%;
        height: 20%;
        margin: 0 auto;
        .name {
          width: 100%;
          height: 30%;
        }
        .pic {
          width: 100%;
          height: 70%;
          img {
            width: 60%;
          }
        }
      }
      .ly2 {
        width: 100%;
        height: 10%;
      }
      .ly3 {
        width: 80%;
        height: 40%;
        margin-left: 10%;
        .ly3_detail {
          width: 25%;
          height: 100%;
          .name {
            position: relative;
            transform: skew(9deg, 20deg);
            margin-left: 30%;
            margin-top: 40%;
          }
        }
        .ly3_detail1 {
          background-image: url("../../assets/Images/qqd/qqd_top/qqd_hpt.png");
          background-position: center 60%;
          background-repeat: no-repeat;
          background-size: 80%;
          .ly3pic1 {
            width: 30%;
            height: 20%;
            margin-left: 35%;
            margin-top: 30%;
            animation: sys1 3s infinite linear;
            -webkit-animation: sys1 3s infinite linear;
            img {
              width: 30%;
            }
          }
          .ly3pic2 {
            width: 30%;
            height: 20%;
            margin-left: 53%;
            margin-top: -31%;
            animation: sys2 4s infinite linear;
            -webkit-animation: sys2 4s infinite linear;
            img {
              width: 35%;
            }
          }
          .ly3pic3 {
            width: 30%;
            height: 20%;
            margin-left: 43%;
            margin-top: -28%;
            animation: sys1 2s infinite linear;
            -webkit-animation: sys1 2s infinite linear;
            img {
              width: 30%;
            }
          }
        }
        .ly3_detail2 {
          background-image: url("../../assets/Images/qqd/qqd_top/qqd_ddt.png");
          background-position: center 65%;
          background-repeat: no-repeat;
          background-size: 70%;
        }
        .ly3_detail3 {
          // background-image: url("../../assets/Images/qqd/qqd_top/qqd_zdt.png");
          // background-position: center 72%;
          // background-repeat: no-repeat;
          // background-size: 45%;
          .ly3pic0 {
            z-index: 1;
            position: relative;
            width: 100%;
            height: 20%;
            margin-left: 21%;
            margin-top: 10%;
            img {
              width: 45%;
            }
          }
          .ly3pic1 {
            width: 100%;
            height: 20%;
            margin-left: 13%;
            margin-top: 15%;
            animation: sys3 5s infinite linear;
            -webkit-animation: sys3 5s infinite linear;
            img {
              width: 60%;
            }
          }
          .ly3pic2 {
            width: 100%;
            height: 20%;
            margin-left: 13%;
            margin-top: -25%;
            animation: sys3 5s infinite linear;
            -webkit-animation: sys3 5s infinite linear;
            img {
              width: 60%;
            }
          }
        }
        .ly3_detail4 {
          background-image: url("../../assets/Images/qqd/qqd_top/qqd_cwt.png");
          background-position: center 68%;
          background-repeat: no-repeat;
          background-size: 80%;
          .ly3pic1 {
            width: 30%;
            height: 20%;
            margin-left: 45%;
            margin-top: 30%;
            animation: sys4 3s infinite linear;
            -webkit-animation: sys4 3s infinite linear;
            img {
              width: 30%;
            }
          }
          .ly3pic2 {
            width: 30%;
            height: 20%;
            margin-left: 70%;
            margin-top: -10%;
            animation: sys5 3s infinite linear;
            -webkit-animation: sys5 3s infinite linear;
            img {
              width: 20%;
              transform: skew(-39deg, 19deg);
            }
          }
          .ly3pic3 {
            width: 30%;
            height: 20%;
            margin-left: 15%;
            margin-top: -40%;
            animation: sys5 3s infinite linear;
            -webkit-animation: sys5 3s infinite linear;
            img {
              width: 20%;
              transform: skew(21deg, 0);
            }
          }
        }
      }
      .ly21 {
        background-image: url("../../assets/Images/qqd/qqd_top/qqd_ddkh.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
      .ly22 {
        background-image: url("../../assets/Images/qqd/qqd_top/qqd_ddkh2.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 80%;
      }
    }
    .lc_right {
      width: 25%;
      height: 100%;
      background-image: url("../../assets/Images/qqd/qqd_top/qqd_ddright.png");
      background-position: 30% center;
      background-repeat: no-repeat;
      background-size: 80%;
      .ly1 {
        position: relative;
        margin-left: -8%;
        margin-top: 91%;
        text-align: center;
        .onoffline{
          //padding-top: 4%;
        }
      }
      .ly2 {
        position: relative;
        margin-left: 77%;
        margin-top: -33%;
      }
      .ly3 {
        position: relative;
        margin-left: 83%;
        margin-top: 14%;
        width: 30%;
      }
      .ly4 {
        position: relative;
        margin-left: 76%;
        margin-top: 16%;
      }
      .lyjt {
        width: 60%;
        height: 20%;
        position: relative;
        margin-left: -20%;
        margin-top: -31%;
        img {
          width: 50%;
        }
      }
    }
  }

  @keyframes sys1 {
    0% {
      transform: translate(0, 0%) rotate(0deg);
      opacity: 0;
    }
    25% {
      transform: translate(0%, -25%) rotate(10deg);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%) rotate(0deg);
      opacity: 1;
    }
    75% {
      transform: translate(0%, -75%) rotate(-10deg);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -100%) rotate(0deg);
      opacity: 0;
    }
  }
  @keyframes sys2 {
    0% {
      transform: translate(0, 0%) rotate(0deg);
      opacity: 0;
    }
    25% {
      transform: translate(0%, -25%) rotate(-20deg);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%) rotate(0deg);
      opacity: 1;
    }
    75% {
      transform: translate(0%, -75%) rotate(20deg);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -100%) rotate(0deg);
      opacity: 0;
    }
  }
  @keyframes sys3 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -20%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
  }
  @keyframes sys4 {
    0% {
      transform: translate(0, 0%);
      opacity: 0;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -100%);
      opacity: 0;
    }
  }
  @keyframes sys5 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
  }
}
</style>
