<template>
  <!--WMS-->
  <div class="zj_wms flexrow" v-if="proData">
    <div class="drp_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="wmspic1">
        <img src="../../assets/Images/xls/wms/wms_d.png" />
      </div>
      <div class="wmspic2">
        <img src="../../assets/Images/xls/wms/wms_d.png" />
      </div>
      <div class="wmspic3">
        <img src="../../assets/Images/xls/wms/wms_d.png" />
      </div>
      <div class="wmspic4">
        <img src="../../assets/Images/xls/wms/wms_d.png" />
      </div>
      <div class="wmspic5">
        <img src="../../assets/Images/xls/wms/wms_c.png" />
      </div>
      <div class="wmspic6">
        <img src="../../assets/Images/xls/wms/wms_k.png" />
      </div>
      <div class="wmsdata1 flexrow">
        <div class="d1 font8rem">
          {{$t("解决了零售行业多小、杂的困难")}}
        </div>
        <div class="d2">
        <img src="../../assets/Images/xls/wms/wms_circle.png" />
        </div>
      </div>
      <div class="wmsdata2 flexrow">
        <div class="d1 font8rem">
          {{$t("打通了B2B和B2C业务流程")}}
        </div>
        <div class="d2">
        <img src="../../assets/Images/xls/wms/wms_circle.png" />
        </div>
      </div>
       <div class="wmsdata3 flexrow">
        <div class="d2">
        <img src="../../assets/Images/xls/wms/wms_circle.png" />
        </div>
        <div class="d1 font8rem">
         {{$t(" 将DRP和WMS无缝对接")}}
        </div>
      </div>
       <div class="wmsdata4 flexrow">
        <div class="d2">
        <img src="../../assets/Images/xls/wms/wms_circle.png" />
        </div>
        <div class="d1 font8rem">
          {{$t("去除了传统WMS的接口")}}
        </div>
      </div>
    </div>
    <div class="drp_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="name flextbc black font10rem">
        <img :src="proData.icon1" />{{ proData.id }}
      </div>
      <div class="detail font8rem black2">
        <p>{{ proData.value1 }}</p>
        <p>{{ proData.value2 }}</p>
        <p>{{ proData.value3 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_wms",
  props: ["proData"],
  data() {
    return {};
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_wms {
  width: 100%;
  height: 100%;
  @keyframes wms1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @keyframes wms2 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -40%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
  @keyframes wms3 {
    0% {
      transform: translate(0, -40%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -40%);
      opacity: 1;
    }
  }
  .drp_left {
    width: 50%;
    height: 100%;
    background-image: url("../../assets/Images/xls/wms/wms_bg.png");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 0 90%;
    .wmspic1 {
      width: 10%;
      //height: 10%;
      margin-left: 23%;
      margin-top: 23%;
      // animation: mdm1 4s infinite linear;
      // -webkit-animation: mdm1 4s infinite linear;
      img {
        width: 100%;
      }
    }
    .wmspic2 {
      width: 10%;
      //height: 10%;
      margin-left: 46%;
      margin-top: -9%;
      // animation: mdm3 3s infinite linear;
      // -webkit-animation: mdm3 3s infinite linear;

      img {
        width: 100%;
      }
    }
    .wmspic3 {
      width: 8%;
      //height: 10%;
          margin-left: 8%;
    margin-top: -11%;
      // animation: mdm2 3s infinite linear;
      // -webkit-animation: mdm2 3s infinite linear;

      img {
        width: 100%;
      }
    }
    .wmspic4 {
      width: 8%;
      //height: 10%;
      margin-left: 64%;
    margin-top: -13%;
      // animation: mdm1 3s infinite linear;
      //-webkit-animation: mdm2 3s infinite linear;
      line-height: 2.5rem;

      img {
        width: 100%;
      }
    }
    .wmspic5 {
      width: 6%;
      //height: 10%;
      margin-left: 24%;
      margin-top: 0%;
      animation: wms3 3s infinite linear;
      -webkit-animation: wms3 3s infinite linear;
      line-height: 2.5rem;
      img {
        width: 100%;
      }
    }
    .wmspic6 {
      width: 6%;
      //height: 10%;
      margin-left: 51%;
      margin-top: -7%;
      animation: wms2 3s infinite linear;
      -webkit-animation: wms2 3s infinite linear;
      line-height: 2.5rem;
      img {
        width: 100%;
      }
    }
    .wmsdata1{
      width: 25%;
      height: 20%; 
      margin-left: 4%;
      margin-top: -38%;
      .d1{
        width: 95%;
        height: 100%;
        text-align: right;
        margin-right: 5%;
      }
    }
    .wmsdata2{
      width: 25%;
      height: 20%; 
      margin-left: -12.2%;
    margin-top: -3%;
      .d1{
        width: 95%;
        height: 100%;
        text-align: right;
        margin-right: 5%;
      }
    }
    .wmsdata3{
      width: 25%;
      height: 20%; 
          margin-left: 50.5%;
    margin-top: -20%;
      .d1{
        width: 95%;
        height: 100%;
        text-align: left;
        margin-right: 5%;
      }
    }
    .wmsdata4{
      width: 25%;
      height: 20%; 
          margin-left: 67.5%;
    margin-top: -10%;
      .d1{
        width: 95%;
        height: 100%;
        text-align: right;
        margin-right: 5%;
      }
    }
  }
  .drp_right {
    width: 50%;
    height: 100%;
    .name {
      width: 100%;
      height: 15%;
      margin-top: 15%;
      img {
        width: 5%;
        margin-right: 2%;
      }
    }
    .detail {
      width: 80%;
      height: 50%;
      line-height: 2.5rem;
    }
  }
}
</style>
