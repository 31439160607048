<template>
  <!--零售数字化移动BI简介-->
  <div class="zj_productIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/bi_icon.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("零售数字化移动BI")}}</div>
        <div class="line3 font8rem black2">
          {{$t("实现大数据的实时性和移动化，同时支持不同维度和角色的数据报表展现，随时随地查阅经营指标，快速准确获取所需的业务数据及分析展现，及时做出运营决策，从而更好地帮助企业进行业务管控。")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="title flexcc blue1 fontweight font8rem">
          {{$t("行业创新 五种形式POS")}}
        </div>
        <div class="case flexcc">
          <img src="../../assets/Images/bi/bi_pic.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productIntroduce",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_productIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/casebg1.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  .zj_inside {
    width: 65%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        width: 100%;
        height: 15%;
        img {
          width: 8%;
        }
      }
      .line3 {
        width: 100%;
        height: 70%;
        line-height: 2.5rem;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .title {
        width: 100%;
        height: 20%;
      }
      .case {
        width: 100%;
        height: 70%;
        img {
          width: 40%;
        }
      }
    }
  }
}
</style>
