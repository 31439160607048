<template>
  <!--FCIO-->
  <div class="zj_fcio flexrow" v-if="proData">
    <div class="drp_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="fciopic1">
        <img src="../../assets/Images/xls/fcio/fcio_y1.png" />
      </div>
      <div class="fciopic2">
        <img src="../../assets/Images/xls/fcio/fcio_y2.png" />
      </div>
      <div class="fciopic3">
        <img src="../../assets/Images/xls/fcio/line2.png" />
      </div>
      <div class="fciopic4">
        <img src="../../assets/Images/xls/fcio/line1.png" />
      </div>
      <div class="fciopic5">
        <img src="../../assets/Images/xls/fcio/line2.png" />
      </div>
    </div>
    <div class="drp_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="name flextbc black font10rem">
        <img :src="proData.icon1" />{{ proData.id }}
      </div>
      <div class="detail font8rem black2">
        <p>{{ proData.value1 }}</p>
        <p>{{ proData.value2 }}</p>
        <p>{{ proData.value3 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_fcio",
  props: ["proData"],
  data() {
    return {};
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_fcio {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/xls/fcio/fcio_bl.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  @keyframes fcio1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -30%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
   @keyframes fcio2 {
    0% {
      transform: translate(0, -30%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, 0%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -30%);
      opacity: 1;
    }
  }
   @keyframes fcio3 {
    0% {
      transform: translate(0, 0%)rotateZ(60deg);
      opacity: 0;
    }
    25% {
      transform: translate(-2100%, 200%)rotateZ(60deg);
      opacity: 1;
    }
    26% {
      transform: translate(-2200%, 200%)rotateZ(-60deg);
      opacity: 1;
    }
    75% {
      transform: translate(2000%, 570%)rotateZ(-55deg);
      opacity: 1;
    }
    76% {
      transform: translate(2000%, 530%)rotateZ(-120deg);
      opacity: 1;
    }
    100% {
      transform: translate(3000%, 440%)rotateZ(-120deg);
      opacity: 0;
    }
  }
   @keyframes fcio4 {
    0% {
      transform: translate(0, 0%)rotateZ(60deg);
      opacity: 1;
    }
    30% {
      transform: translate(1700%, -160%)rotateZ(60deg);
      opacity: 1;
    }
    31% {
      transform: translate(1700%, -195%)rotateZ(-60deg);
      opacity: 1;
    }
    
    100% {
      transform: translate(-2000%, -530%)rotateZ(-60deg);
      opacity: 0;
    }
  }
  
   @keyframes fcio5 {
    0% {
      transform: translate(0, 0%)rotateZ(-60deg);
      opacity: 1;
    }
    70% {
     transform: translate(2200%, 200%)rotateZ(-60deg);
      opacity: 1;
    }
    71% {
     transform: translate(2500%, 220%)rotateZ(60deg);
      opacity: 1;
    }
    100% {
      transform: translate(600%, 390%)rotateZ(60deg);
      opacity: 0;
    }
  }
  .drp_left {
    width: 50%;
    height: 100%;
    background-image: url("../../assets/Images/xls/fcio/fcio_pic.png");
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 0 90%;
    .fciopic1 {
      width: 10%;
      //height: 10%;
      margin-left: 7%;
      margin-top: 22%;
      animation: fcio1 3s infinite linear;
      -webkit-animation: fcio1 3s infinite linear;
      img {
        width: 100%;
      }
    }
    .fciopic2 {
      width: 6%;
      //height: 10%;
      margin-left: 75%;
      margin-top: -5%;
      animation: fcio2 3s infinite linear;
      -webkit-animation: fcio2 3s infinite linear;
      img {
        width: 100%;
      }
    }
    .fciopic3 {
      width: 1%;
      //height: 10%;
      margin-left: 21%;
      margin-top: 0%;
      animation: fcio3 3s infinite linear;
      -webkit-animation: fcio3 3s infinite linear;
      img {
        width: 80%;
      }
    }
    .fciopic4 {
      width: 1%;
      //height: 10%;
      margin-left: 71%;
    margin-top: 12%;
      animation: fcio4 3s infinite linear;
      -webkit-animation: fcio4 3s infinite linear;
      img {
        width: 80%;
      }
    }
    .fciopic5 {
      width: 1%;
      //height: 10%;
          margin-left: 61%;
    margin-top: -33%;
      animation: fcio5 3s infinite linear;
      -webkit-animation: fcio5 3s infinite linear;
      img {
        width: 80%;
      }
    }
  }
  .drp_right {
    width: 50%;
    height: 100%;
    .name {
      width: 100%;
      height: 15%;
      margin-top: 15%;
      img {
        width: 5%;
        margin-right: 2%;
      }
    }
    .detail {
      width: 80%;
      height: 50%;
      line-height: 2.5rem;
    }
  }
}
</style>
