<template>
  <!--专属导购在线营销服务-->
  <div class="zj_guideProcess">
    <div class="name1 font16rem black flexcc wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("专属导购在线营销服务")}}</div>
    <div class="guidePro wow bounceInUp"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="gp_pic1 flexcc">
        <img src="../../assets/Images/qqd/lc/dg.png" />
      </div>
      <div class="gp_pic2 flexcc">
        <img src="../../assets/Images/qqd/lc/jt1.png" />
      </div>
      <div class="gp_pic3 flexcc gp_picbg1">
        <img src="../../assets/Images/qqd/lc/icon1.png" />
      </div>
      <div class="gp_pic2 flexcc">
        <img src="../../assets/Images/qqd/lc/jt1.png" />
      </div>
      <div class="gp_pic4 flexcc">
        <img src="../../assets/Images/qqd/lc/jt2.png" />
      </div>
      <div class="gp_pic5 flexcc">
        <img src="../../assets/Images/qqd/lc/xfz.png" />
      </div>
      <div class="gp_pic6 flexcc">
        <img src="../../assets/Images/qqd/lc/jt3.png" />
      </div>
      <div class="gp_pic7 flexcc gp_picbg2">
        <img src="../../assets/Images/qqd/lc/icon3.png" />
      </div>
      <div class="gp_pic8 flexcc gp_picbg2">
        <img src="../../assets/Images/qqd/lc/icon2.png" />
      </div>
      <div class="gp_pic9 flexcc">
        <img src="../../assets/Images/qqd/lc/jt4.png" />
      </div>
      <div class="gp_pic10 flexcc">
        <img src="../../assets/Images/qqd/lc/dg2.png" />
      </div>
      <div class="gp_pic11 gp_picbg3 flexrow">
        <div class="left gp_picbg4 font8rem black2">
          <img src="../../assets/Images/qqd/lc/icon4.png" />
          <p>{{$t("门店发货")}}</p>
        </div>
        <div class="right gp_picbg4 font8rem black2">
          <img src="../../assets/Images/qqd/lc/icon6.png" />
          <p>{{$t("移动端发货")}}</p>
        </div>
      </div>
      <div class="gp_pic12 flexcc">
        <img src="../../assets/Images/qqd/lc/jt5.png" />
      </div>
      <div
        class="gp_data gp_data1 flexcc font10rem black2"
        @mouseover="selectPro('gp_data1')"
        @click="selectPro('gp_data1')"
      >
        {{$t("确认需求及购买意愿")}}
      </div>
      <div
        class="gp_data gp_data2 flexcc font10rem black2"
        @mouseover="selectPro('gp_data2')"
        @click="selectPro('gp_data2')"
      >
        {{$t("分享商品卡片")}}
      </div>
      <div
        class="gp_data gp_data3 flexcc font10rem black2"
        @mouseover="selectPro('gp_data3')"
        @click="selectPro('gp_data3')"
      >
        {{$t("支付结算")}}
      </div>
      <div
        class="gp_data gp_data4 flexcc font10rem black2"
        @mouseover="selectPro('gp_data4')"
        @click="selectPro('gp_data4')"
      >
        {{$t("优惠券/优惠活动查看")}}
      </div>
      <div
        class="gp_data gp_data5 flexcc font10rem black2"
        @mouseover="selectPro('gp_data5')"
        @click="selectPro('gp_data5')"
      >
        {{$t("加入购物车")}}
      </div>
      <div
        class="gp_data gp_data6 flexcc font10rem black2"
        @mouseover="selectPro('gp_data6')"
        @click="selectPro('gp_data6')"
      >
        {{$t("查看商品详情")}}
      </div>
      <div
        class="gp_data gp_data7 flexcc font10rem black2"
        @mouseover="selectPro('gp_data7')"
        @click="selectPro('gp_data7')"
      >
        {{$t("物流追踪")}}
      </div>
      <div
        class="gp_phone"
        :class="sel == 'gp_data1' ? 'gp_phone1' : 'gp_phone2'"
      >
        <div class="sel_data1" v-show="sel == 'gp_data1'">
          <img src="../../assets/Images/qqd/lc/xq.png" />
        </div>
        <div class="sel_data2" v-show="sel == 'gp_data2'">
          <div class="swiper-container sel2_container">
            <div class="swiper-wrapper">
              <div class="swiper-slide flexcc">
                <img src="../../assets/Images/qqd/lc/fx.png" />
              </div>
              <div class="swiper-slide flexcc">
                <img src="../../assets/Images/qqd/lc/fx3.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="sel_data3" v-show="sel == 'gp_data3'">
          <img src="../../assets/Images/qqd/lc/zfjs.png" />
        </div>
        <div class="sel_data4" v-show="sel == 'gp_data4'">
          <img src="../../assets/Images/qqd/lc/yhq.png" />
        </div>
        <div class="sel_data5" v-show="sel == 'gp_data5'">
          <img src="../../assets/Images/qqd/lc/gwc.png" />
        </div>
        <div class="sel_data6" v-show="sel == 'gp_data6'">
          <img src="../../assets/Images/qqd/lc/spxq.png" />
        </div>
        <div class="sel_data7" v-show="sel == 'gp_data7'">
          <img src="../../assets/Images/qqd/lc/wjzz.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_guideProcess",
  data() {
    return {
      sysData: [
        {
          index: 1,
          name: this.$t("精准营销"),
          icon: require("../../assets/Images/qqd/qqd_icon1.png"),
          pic: require("../../assets/Images/qqd/qqd_demo2.png"),
        },
        {
          index: 2,
          name: this.$t("猜Ta喜欢"),
          icon: require("../../assets/Images/qqd/qqd_icon3.png"),
          pic: require("../../assets/Images/qqd/qqd_demo3.png"),
        },
        {
          index: 3,
          name: this.$t("消费记录"),
          icon: require("../../assets/Images/qqd/qqd_icon4.png"),
          pic: require("../../assets/Images/qqd/qqd_demo4.png"),
        },
        {
          index: 4,
          name: this.$t("用户画像"),
          icon: require("../../assets/Images/qqd/qqd_icon2.png"),
          pic: require("../../assets/Images/qqd/qqd_demo1.png"),
        },
        {
          index: 5,
          name: this.$t("扫码购"),
          icon: require("../../assets/Images/qqd/qqd_icon5.png"),
          pic: require("../../assets/Images/qqd/qqd_demo5.png"),
        },
      ],
      sel: "gp_data1",
      timer1: null,
      timer2: null,
      timer3: null,
      timer4: null,
      timer5: null,
      timer6: null,
      timer7: null,
      timer10:null,
      i: 0,
    };
  },
  watch: {
   
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
    this.selectPro("gp_data1");
    this.swipersel2();
    let _this=this
   // this.timer10 = setTimeout(this.timeFun(), 10000);
   clearInterval(this.timer10)
    this.timer10 =setInterval(function(){_this.timeFun();},40000);
    this.timeFun();
   // clearInterval(this.timer10)
    //  new this.$wow.WOW({
    //   live: false,
    // duration:"2s",
    //   delay:"0",
    //   offset:"10",
    //   iteration:"1",
    // }).init()
    //new WOW().init()
  },
  methods: {
    selectPro(e) {
      //console.log(this.i++);
      //console.log(e);
      // $(".gp_data1").click(function(){
      $("." + e).removeClass("black2");
      $("." + e)
        .addClass("blue1")
        .siblings()
        .removeClass("blue1");
      this.sel = e;
      //});
    },
    swipersel2() {
      let swipernews = new Swiper(".sel2_container", {
        direction: "horizontal",
        autoplay: 3000,
        loop: true,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: "0%",
      });
    },
    timeFun() {
      //alert("12123");
      clearInterval(this.timer1);
      clearInterval(this.timer2);
      clearInterval(this.timer3);
      clearInterval(this.timer4);
      clearInterval(this.timer5);
      clearInterval(this.timer6);
      clearInterval(this.timer7);
      let that=this;
      this.timer1 = setTimeout(function(){that.selectPro("gp_data1")}, 5000);
      this.timer2 = setTimeout(function(){that.selectPro("gp_data2")}, 10000);
      this.timer3 = setTimeout(function(){that.selectPro("gp_data3")}, 15000);
      this.timer4 = setTimeout(function(){that.selectPro("gp_data4")}, 20000);
      this.timer5 = setTimeout(function(){that.selectPro("gp_data5")}, 25000);
      this.timer6 = setTimeout(function(){that.selectPro("gp_data6")}, 30000);
      this.timer7 = setTimeout(function(){that.selectPro("gp_data7")}, 35000);
    },
  },
};
</script>

<style scoped lang="scss">
.zj_guideProcess {
  width: 100%;
  height: 100%;
  .name1 {
    width: 80%;
    height: 6%;
    margin: 0 auto;
  }
  .guidePro {
    width: 70%;
    height: 94%;
    margin: 0 auto;
    .gp_data {
      cursor: pointer;
    }
    .gp_picbg1 {
      background-image: url("../../assets/Images/qqd/lc/icon_bg.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 70%;
    }
    .gp_picbg2 {
      background-image: url("../../assets/Images/qqd/lc/icon_bg.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 60%;
    }
    .gp_picbg3 {
      background-image: url("../../assets/Images/qqd/lc/jx.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 100%;
    }
    .gp_picbg4 {
      background-image: url("../../assets/Images/qqd/lc/icon_bg.png");
      background-position: center 60%;
      background-repeat: no-repeat;
      background-size: 50%;
    }
    .gp_pic1 {
      width: 10%;
      height: 18%;
      position: relative;
      img {
        width: 100%;
      }
    }
    .gp_pic2 {
      width: 10%;
      height: 18%;
      position: relative;
      img {
        width: 11%;
      }
    }
    .gp_pic3 {
      width: 10%;
      height: 18%;
      position: relative;
      img {
        width: 25%;
        margin-bottom: 20%;
        animation: lc1 3s infinite linear;
        -webkit-animation: lc1 3s infinite linear;
      }
    }
    .gp_pic4 {
      width: 100%;
      height: 4%;
      position: relative;
      img {
        width: 100%;
      }
    }
    .gp_pic5 {
      width: 10%;
      height: 18%;
      position: relative;
      margin-left: 55%;
      margin-top: -8%;
      img {
        width: 100%;
      }
    }
    .gp_pic6 {
      width: 1%;
      height: 100%;
      position: relative;
      margin-left: 59.7%;
      margin-top: -48%;
      img {
        width: 43.5%;
      }
    }
    .gp_pic7 {
      width: 10%;
      height: 18%;
      margin-left: 55%;
      margin-top: -33%;
      position: relative;
      img {
        width: 20%;
        margin-bottom: 10%;
        animation: lc1 3s infinite linear;
        -webkit-animation: lc1 3s infinite linear;
      }
    }
    .gp_pic8 {
      width: 10%;
      height: 18%;
      margin-left: 55%;
      margin-top: -25%;
      position: relative;
      img {
        width: 20%;
        margin-bottom: 10%;
        animation: lc1 3s infinite linear;
        -webkit-animation: lc1 3s infinite linear;
      }
    }
    .gp_pic9 {
      width: 30%;
      height: 4%;
      position: relative;
      margin-left: 63%;
      margin-top: -4%;
      img {
        width: 100%;
      }
    }
    .gp_pic10 {
      width: 10%;
      height: 18%;
      margin-left: 93%;
      margin-top: -8%;
      position: relative;
      img {
        width: 100%;
      }
    }
    .gp_pic11 {
      width: 20%;
      height: 23%;
      margin-left: 89%;
      margin-top: 0%;
      position: relative;
      .left,
      .right {
        width: 50%;
        height: 90%;
        img {
          width: 20%;
          margin-left: 40%;
          margin-bottom: -5%;
          margin-top: 30%;
          animation: lc1 3s infinite linear;
          -webkit-animation: lc1 3s infinite linear;
        }
      }
      p {
        margin-top: 45%;
        text-align: center;
      }
    }
    .gp_pic12 {
      width: 12%;
      height: 33%;
      margin-left: 94%;
      margin-top: 0%;
      position: relative;
      img {
        width: 11%;
      }
    }
    .gp_data1 {
      width: 10%;
      height: 10%;
      margin-left: 10%;
      margin-top: -27%;
    }
    .gp_data2 {
      width: 10%;
      height: 10%;
      margin-left: 10%;
      margin-top: 13%;
    }
    .gp_data3 {
      width: 10%;
      height: 10%;
      margin-left: 73%;
      margin-top: -35%;
    }
    .gp_data4 {
      width: 15%;
      height: 10%;
      margin-left: 52%;
      margin-top: 1%;
    }
    .gp_data5 {
      width: 15%;
      height: 10%;
      margin-left: 62%;
      margin-top: -1%;
    }
    .gp_data6 {
      width: 15%;
      height: 10%;
      margin-left: 62%;
      margin-top: 8%;
    }
    .gp_data7 {
      width: 15%;
      height: 10%;
      margin-left: 71%;
      margin-top: 8%;
    }
  }
  .gp_phone1 {
    background-image: url("../../assets/Images/qqd/lc/phone.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .gp_phone2 {
    background-image: url("../../assets/Images/qqd/lc/phone2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .gp_phone {
    width: 15%;
    height: 56%;
    margin-top: -40%;
    margin-left: 28%;
    .sel_data1,
    .sel_data3,
    .sel_data4,
    .sel_data5,
    .sel_data6,
    .sel_data7 {
      width: 100%;
      height: 100%;
      img {
        margin-top: 1%;
        width: 100%;
      }
    }
    .sel_data2 {
      width: 100%;
      height: 100%;
      .sel2_container {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
      }
    }
    .sel_data3 {
      width: 100%;
      height: 100%;
      img {
        //margin-top: 5%;
        width: 100%;
      }
    }
  }
  @keyframes lc1 {
    0% {
      transform: translate(0, 0%);
      opacity: 1;
    }
    50% {
      transform: translate(0%, -50%);
      opacity: 1;
    }
    100% {
      transform: translate(0%, -0%);
      opacity: 1;
    }
  }
}
</style>
