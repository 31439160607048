<template>
  <!--CRM-->
  <div class="zj_crm flexrow" v-if="proData">
    <div class="drp_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="0"
      data-wow-iteration="1">
      <div class="crm1">
        <img src="../../assets/Images/xls/crm/crm_cap.png" />
        <div class="bgname white font8rem flexcc">{{$t("Capillary(客提利)CRM")}}</div>
      </div>
      <div class="crm2">
        <img src="../../assets/Images/xls/crm/crm_d.png" />
        <div class="bgname white font8rem flexcc">{{$t("达摩CRM")}}</div>
      </div>
      <div class="crm3">
        <img src="../../assets/Images/xls/crm/crm_hcr.png" />
        <div class="bgname white font8rem flexcc">{{$t("HCR(慧辰资讯)CRM")}}</div>
      </div>
      <div class="crm4">
        <img src="../../assets/Images/xls/crm/crm_mic.png" />
        <div class="bgname white font8rem flexcc">{{$t("微软CRM")}}</div>
      </div>
      <div class="crm5">
        <img src="../../assets/Images/xls/crm/crm_sy.png" />
        <div class="bgname white font8rem flexcc">{{$t("数云CRM")}}</div>
      </div>
      <div class="crm6">
        <img src="../../assets/Images/xls/crm/crm_wm.png" />
        <div class="bgname white font8rem flexcc">{{$t("微盟CRM")}}</div>
      </div>
      <div class="crm7">
        <img src="../../assets/Images/xls/crm/crm_xr.png" />
        <div class="bgname white font8rem flexcc">{{$t("枭荣CRM")}}</div>
      </div>
    </div>
    <div class="drp_right wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      :data-wow-offset="10"
     :data-wow-iteration="1">
      <div class="name flextbc black font10rem">
        <img :src="proData.icon1" />{{ proData.id }}
      </div>
      <div class="detail font8rem black2">
        <p>{{ proData.value1 }}</p>
        <p>{{ proData.value2 }}</p>
        <p>{{ proData.value3 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_crm",
  props: ["proData","selected"],
  data() {
    return {};
  },
  watch: {
    selected(newval, oldval) {
      console.log("newval", newval);
      console.log("oldval", oldval);
//       if(newval=="CRM"){
// $(".drp_right").addClass("wow");
// $(".drp_right").addClass("bounceInRight");
//     }else{
// $(".drp_right").removeClass("wow")
// $(".drp_right").removeClass("bounceInRight");
//     }
    },
  },
  
  mounted() {
    console.log(this.selected)
    
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_crm {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/xls/crm/crm_bl.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 0% center;
  @keyframes crm1 {
    0% {
      transform: translate(0, 0%);
    }
    50% {
      transform: translate(0%, -50%);
    }
    100% {
      transform: translate(0%, -0%);
    }
  }

  @keyframes crm4 {
    0% {
      transform: translate(0, 0%);
    }
    50% {
      transform: translate(0%, 50%);
    }
    100% {
      transform: translate(0%, 0%);
    }
  }

  @keyframes crm2 {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes crm3 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  .drp_left {
    width: 50%;
    height: 100%;
    .crm1 {
      width: 50%;
      height: 10%;
      position: relative;
          margin-top: 15%;
    margin-left: 2%;
      animation: crm4 3s infinite linear;
      -webkit-animation: crm4 3s infinite linear;
      img {
        width: 15%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -20%;
        margin-left: 15%;
        background-image: url("../../assets/Images/xls/crm/crm_text.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 80%;
        animation: crm2 10s infinite linear;
        -webkit-animation: crm2 10s infinite linear;
      }
    }
    .crm2 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: 10%;
      margin-left: 19%;
      animation: crm1 3s infinite linear;
      -webkit-animation: crm1 3s infinite linear;
      img {
        width: 20%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -20%;
        margin-left: 15%;
        background-image: url("../../assets/Images/xls/crm/crm_text2.png");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center 80%;
        animation: crm3 10s infinite linear;
        -webkit-animation: crm3 10s infinite linear;
      }
    }
    .crm3 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: -30%;
      margin-left: 80%;
      animation: crm4 3s infinite linear;
      -webkit-animation: crm4 3s infinite linear;
      img {
        width: 20%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -25%;
        margin-left: -50%;
        background-image: url("../../assets/Images/xls/crm/crm_text3.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 80%;
        animation: crm2 10s infinite linear;
        -webkit-animation: crm2 10s infinite linear;
      }
    }
    .crm4 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: 24%;
      margin-left: 38%;
      animation: crm1 3s infinite linear;
      -webkit-animation: crm1 3s infinite linear;
      img {
        width: 15%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -20%;
        margin-left: 15%;
        background-image: url("../../assets/Images/xls/crm/crm_text.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 80%;
        animation: crm2 10s infinite linear;
        -webkit-animation: crm2 10s infinite linear;
      }
    }
    .crm5 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: -29%;
    margin-left: 12%;
      animation: crm4 3s infinite linear;
      -webkit-animation: crm4 3s infinite linear;
      img {
        width: 17%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -20%;
        margin-left: 15%;
        background-image: url("../../assets/Images/xls/crm/crm_text2.png");
        background-repeat: no-repeat;
        background-size: 70%;
        background-position: center 80%;
        animation: crm3 10s infinite linear;
        -webkit-animation: crm3 10s infinite linear;
      }
    }
    .crm6 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: -0%;
      margin-left: 80%;
      animation: crm1 3s infinite linear;
      -webkit-animation: crm1 3s infinite linear;
      img {
        width: 20%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -25%;
        margin-left: -50%;
        background-image: url("../../assets/Images/xls/crm/crm_text3.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 80%;
        animation: crm3 10s infinite linear;
        -webkit-animation: crm3 10s infinite linear;
      }
    }
    .crm7 {
      width: 50%;
      height: 10%;
      position: relative;
      margin-top: -18%;
      margin-left: 60%;
      animation: crm1 3s infinite linear;
      -webkit-animation: crm1 3s infinite linear;
      img {
        width: 20%;
      }
      .bgname {
        width: 50%;
        height: 100%;
        position: relative;
        margin-top: -25%;
        margin-left: -50%;
        background-image: url("../../assets/Images/xls/crm/crm_text3.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center 80%;
        animation: crm2 10s infinite linear;
        -webkit-animation: crm2 10s infinite linear;
      }
    }
  }
  .drp_right {
    width: 50%;
    height: 100%;
    .name {
      width: 100%;
      height: 15%;
      margin-top: 15%;
      img {
        width: 5%;
        margin-right: 2%;
      }
    }
    .detail {
      width: 80%;
      height: 50%;
      line-height: 2.5rem;
    }
  }
}
</style>
