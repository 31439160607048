<template>
  <!--全面提升数字化门店运营能力-->
  <div class="zj_oaCapacity wow bounceIn"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
    <div class="cnname flexcc font16rem black padt5">
      {{$t("全面提升数字化门店运营能力")}}
    </div>
    <div class="cnname flexcc black">
      <span class="blue1 fontweight font16rem">50000+</span>
      <span class="black2 font12rem">{{$t("家门店")}}</span>
    </div>
    <div class="cnname flexcc font12rem black2">{{$t("共同的选择")}}</div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_oaCapacity",
  data() {
    return {};
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_oaCapacity {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  background-image: url("../../assets/Images/linebg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 0;
  .cnname {
    width: 100%;
    height: 15%;
  }
  .padt5 {
    padding-top: 5%;
  }
}
</style>
