<template>
  <!--智慧银收系统简介-->
  <div class="zj_productIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/pos.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("POS+智慧收银系统")}}</div>
        <div class="line3 font8rem black2">
          {{$t("魔元术POS+移动数字化管理解决方案是实现企业对所有终端门店及线上门店统一管控，提高实体收银、营销、会员经营、导购管理、线上线下融合等管理能力。帮助门店提升顾客体验与服务升级的精细化管理系统。")}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="title flexcc blue1 fontweight font8rem">
          {{$t("行业创新 五种形式POS")}}
        </div>
        <div class="case flexcc">
           <div class="swiper-container pospic_container">
        <div class="swiper-wrapper">
          <div class="swiper-slide flexcc" v-for="item in posPic" :key="item.index">
          <img :src="item.pic" />
        </div>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productIntroduce",
  data() {
    return {
      posPic: [{
        index:1,
        pic:require("../../assets/Images/macbook1.png")
      },{
        index:2,
        pic:require("../../assets/Images/macbook2.png")
      },{
        index:3,
        pic:require("../../assets/Images/macbook3.png")
      },{
        index:4,
        pic:require("../../assets/Images/macbook4.png")
      },{
        index:5,
        pic:require("../../assets/Images/macbook5.png")
      },],
    };
  },
  watch: {},
  mounted() {
    this.swiperPos();
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
    swiperPos() {
      let swiper1 = new Swiper(".pospic_container", {
        direction: "horizontal",
        autoplay: 3000,
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 1,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_productIntroduce {
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/casebg1.png");
  background-position: 100% 0%;
  background-repeat: no-repeat;
  background-size: 100%;
  .zj_inside {
    width: 65%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        width: 100%;
        height: 15%;
        img {
          width: 8%;
        }
      }
      .line3 {
        width: 100%;
        height: 70%;
        line-height: 2.5rem;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .title {
        width: 100%;
        height: 20%;
      }
      .case {
        width: 100%;
        height: 70%;
        .pospic_container{
          width: 100%;
          height: 100%;
        }
        img {
          width: 94%;
        }
      }
    }
  }
}
</style>
