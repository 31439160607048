<template>
  <!--DRP-->
  <div class="zj_drp flexrow">
    <div class="drp_left">
      <div class="top_pic">
        <img src="../../assets/Images/xls/eb/eb_pic2.png" />
      </div>
      <div
        class="ebpic"
        :class="item.class"
        v-for="item in picData"
        :key="item.index"
      >
        <img :src="item.brand" />
      </div>
    </div>
    <div class="drp_right">
      <div class="name flextbc black font14rem">{{$t("魔元术新零售一体化系统-EB")}}</div>
      <div class="detail font8rem black">
        <p>{{$t("支持各平台通过API获取到")}}</p>
        <p>{{$t("EB系统订单池")}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_drp",
  props: [""],
  data() {
    return {
      picData: [
        {
          index: 1,
          brand: require("../../assets/Images/xls/eb/eb_tm.png"),
          class: "ebpic1",
        },
        {
          index: 2,
          brand: require("../../assets/Images/xls/eb/eb_bs.png"),
          class: "ebpic2",
        },
        {
          index: 3,
          brand: require("../../assets/Images/xls/eb/eb_dd.png"),
          class: "ebpic3",
        },
        {
          index: 4,
          brand: require("../../assets/Images/xls/eb/eb_dy.png"),
          class: "ebpic4",
        },
        {
          index: 5,
          brand: require("../../assets/Images/xls/eb/eb_elm.png"),
          class: "ebpic5",
        },
        {
          index: 6,
          brand: require("../../assets/Images/xls/eb/eb_fn.png"),
          class: "ebpic6",
        },
        {
          index: 7,
          brand: require("../../assets/Images/xls/eb/eb_hqbs.png"),
          class: "ebpic7",
        },
        {
          index: 8,
          brand: require("../../assets/Images/xls/eb/eb_jd.png"),
          class: "ebpic8",
        },
        {
          index: 9,
          brand: require("../../assets/Images/xls/eb/eb_jm.png"),
          class: "ebpic9",
        },
        {
          index: 10,
          brand: require("../../assets/Images/xls/eb/eb_jp.png"),
          class: "ebpic10",
        },
        {
          index: 11,
          brand: require("../../assets/Images/xls/eb/eb_jst.png"),
          class: "ebpic11",
        },
        {
          index: 12,
          brand: require("../../assets/Images/xls/eb/eb_kl.png"),
          class: "ebpic12",
        },
        {
          index: 13,
          brand: require("../../assets/Images/xls/eb/eb_mgj.png"),
          class: "ebpic13",
        },
        {
          index: 14,
          brand: require("../../assets/Images/xls/eb/eb_mt.png"),
          class: "ebpic14",
        },
        {
          index: 15,
          brand: require("../../assets/Images/xls/eb/eb_pdd.png"),
          class: "ebpic15",
        },
        {
          index: 16,
          brand: require("../../assets/Images/xls/eb/eb_sk.png"),
          class: "ebpic16",
        },
        {
          index: 17,
          brand: require("../../assets/Images/xls/eb/eb_sn.png"),
          class: "ebpic17",
        },
        {
          index: 18,
          brand: require("../../assets/Images/xls/eb/eb_tb.png"),
          class: "ebpic18",
        },
        {
          index: 19,
          brand: require("../../assets/Images/xls/eb/eb_wd.png"),
          class: "ebpic19",
        },
        {
          index: 20,
          brand: require("../../assets/Images/xls/eb/eb_wm.png"),
          class: "ebpic20",
        },
        {
          index: 21,
          brand: require("../../assets/Images/xls/eb/eb_wph.png"),
          class: "ebpic21",
        },
        {
          index: 22,
          brand: require("../../assets/Images/xls/eb/eb_xhs.png"),
          class: "ebpic22",
        },
        {
          index: 23,
          brand: require("../../assets/Images/xls/eb/eb_yz.png"),
          class: "ebpic23",
        },
        {
          index: 24,
          brand: require("../../assets/Images/xls/eb/eb_zhe.png"),
          class: "ebpic24",
        },
      ],
    };
  },
  watch: {},
  mounted() {
    console.log("EB")
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_drp {
  width: 100%;
  height: 100%;
  .drp_left {
    width: 50%;
    height: 100%;
    .top_pic {
      width: 50%;
      height: 50%;
      position: relative;
      top: 0%;
      margin-left: 10%;
      z-index: 1;
      img {
        width: 125%;
      }
    }
    @keyframes eb1 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(64%, -80%);
        opacity: 1;
      }
      100% {
        transform: translate(80%, -100%);
        opacity: 0;
      }
    }
    @keyframes eb2 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(64%, -240%);
        opacity: 1;
      }
      100% {
        transform: translate(80%, -300%);
        opacity: 0;
      }
    }
    @keyframes eb3 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(64%, 80%);
        opacity: 1;
      }
      100% {
        transform: translate(80%, 100%);
        opacity: 0;
      }
    }
    @keyframes eb4 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(64%, 240%);
        opacity: 1;
      }
      100% {
        transform: translate(80%, 300%);
        opacity: 0;
      }
    }
    @keyframes eb5 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(-64%, -240%);
        opacity: 1;
      }
      100% {
        transform: translate(-80%, -300%);
        opacity: 0;
      }
    }
    @keyframes eb6 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(-64%, -80%);
        opacity: 1;
      }
      100% {
        transform: translate(-80%, -100%);
        opacity: 0;
      }
    }
    @keyframes eb7 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(-64%, 240%);
        opacity: 1;
      }
      100% {
        transform: translate(-80%, 300%);
        opacity: 0;
      }
    }
    @keyframes eb8 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      80% {
        transform: translate(-64%, 80%);
        opacity: 1;
      }
      100% {
        transform: translate(-80%, 100%);
        opacity: 0;
      }
    }
    .ebpic1 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb1 5s infinite linear;
      -webkit-animation: eb1 5s infinite linear;
      img {
        width: 20%;
      }
    }

    .ebpic2,
    .ebpic3,
    .ebpic4,
    .ebpic5,
    .ebpic6,
    .ebpic7,
    .ebpic8,
    .ebpic9,
    .ebpic10,
    .ebpic11,
    .ebpic12,
    .ebpic13,
    .ebpic1,
    .ebpic15,
    .ebpic16,
    .ebpic17,
    .ebpic18,
    .ebpic19,
    .ebpic20,
    .ebpic21,
    .ebpic22,
    .ebpic23,
    .ebpic24 {
      // margin-top: -10%;
      // margin-left: 45%;
    }
    .ebpic2 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb2 5s infinite linear;
      -webkit-animation: eb2 5s infinite linear;
      img {
        width: 15%;
      }
    }
    .ebpic3 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb3 5s infinite linear;
      -webkit-animation: eb3 5s infinite linear;
      img {
        width: 18%;
      }
    }
    .ebpic4 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb4 5s infinite linear;
      -webkit-animation: eb4 5s infinite linear;
      img {
        width: 13%;
      }
    }
    .ebpic5 {
      margin-top: -15%;
      margin-left: 35%;
      animation: eb5 5s infinite linear;
      -webkit-animation: eb5 5s infinite linear;
      img {
        width: 15%;
      }
    }
    .ebpic6 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb6 5s infinite linear;
      -webkit-animation: eb6 5s infinite linear;
      img {
        width: 18%;
      }
    }
    .ebpic7 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb7 5s infinite linear;
      -webkit-animation: eb7 5s infinite linear;
      img {
        width: 16%;
      }
    }
    .ebpic8 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb8 5s infinite linear;
      -webkit-animation: eb8 5s infinite linear;
      img {
        width: 20%;
      }
    }
    .ebpic9 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb1 5s infinite linear;
      -webkit-animation: eb1 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 17%;
      }
    }
    .ebpic10 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb2 5s infinite linear;
      -webkit-animation: eb2 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 15%;
      }
    }
    .ebpic11 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb3 5s infinite linear;
      -webkit-animation: eb3 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 18%;
      }
    }
    .ebpic12 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb6 5s infinite linear;
      -webkit-animation: eb6 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 20%;
      }
    }
    .ebpic13 {
      margin-top: 10%;
      margin-left: 35%;
      animation: eb3 5s infinite linear;
      -webkit-animation: eb3 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 16%;
      }
    }
    .ebpic14 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb4 5s infinite linear;
      -webkit-animation: eb4 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 14%;
      }
    }
    .ebpic15 {
      margin-top: -15%;
      margin-left: 35%;
      animation: eb5 5s infinite linear;
      -webkit-animation: eb5 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 16%;
      }
    }
    .ebpic16 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb6 5s infinite linear;
      -webkit-animation: eb6 5s infinite linear;
      animation-delay: 2s;
      img {
        width: 18%;
      }
    }
    .ebpic17 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb7 5s infinite linear;
      -webkit-animation: eb7 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 20%;
      }
    }
    .ebpic18 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb5 5s infinite linear;
      -webkit-animation: eb5 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 14%;
      }
    }
    .ebpic19 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb1 5s infinite linear;
      -webkit-animation: eb1 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 17%;
      }
    }
    .ebpic20 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb6 5s infinite linear;
      -webkit-animation: eb6 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 13%;
      }
    }
    .ebpic21 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb2 5s infinite linear;
      -webkit-animation: eb2 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 20%;
      }
    }
    .ebpic22 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb2 5s infinite linear;
      -webkit-animation: eb2 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 18%;
      }
    }
    .ebpic23 {
      margin-top: -10%;
      margin-left: 35%;
      animation: eb3 5s infinite linear;
      -webkit-animation: eb3 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 16%;
      }
    }
    .ebpic24 {
      margin-top: 10%;
      margin-left: 35%;
      animation: eb4 5s infinite linear;
      -webkit-animation: eb4 5s infinite linear;
      animation-delay: 6s;
      img {
        width: 14%;
      }
    }
    .ebpic {
      width: 50%;
      //height: 45%;
      position: relative;
    }
  }
  .drp_right {
    width: 50%;
    height: 100%;
    .name {
      width: 100%;
      height: 15%;
      margin-top: 15%;
      img {
        margin-right: 2%;
      }
    }
    .detail {
      width: 80%;
      height: 50%;
      line-height: 2.5rem;
    }
  }
}
</style>
