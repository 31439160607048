<template>
  <!--产品优势-->
  <div class="zj_productAdvantages">
    <div class="cnname font16rem black wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("产品优势")}}</div>
    <div class="nameline wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div class="serveSlide">
      <div class="adv_part wow bounceInRight" v-for="item in productAdv" :key="item.index" :class="item.index%4!=0?'line':'line2'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="name flexcc font12rem black">{{item.name}}</div>
        <div class="detail font8rem black2">{{item.detail}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_productAdvantages",
  data() {
    return {
      productAdv: [
        {
          index: 1,
          name: this.$t("提高决策效率"),
          detail:
            this.$t("实时全面的数据交互分析，一目了然的业务数据展示，高效的团队分享协作"),
          icon: require("../../assets/Images/jcxl.png"),
        },
        {
          index: 2,
          name: this.$t("丰富的可视化组件"),
          detail:
            this.$t("提供丰富的统计分析图表组件、Web组态图、地图等，以及行业特色化的场景仪表盘，满足各类数据展现需求"),
          icon: require("../../assets/Images/kshzj.png"),
        },
        {
          index: 3,
          name: this.$t("丰富的应用场景"),
          detail:
            this.$t("驾驶舱、宇宙、机场航班等场景，支持发布到多场景的数据大屏上进行可视化集中管控"),
          icon: require("../../assets/Images/yycj.png"),
        },
        {
          index: 4,
          name: this.$t("轻松认知数据"),
          detail:
            this.$t("数据双向互动，提供给用户对大数据可视化需求的无限助力与可能，让非专业用户也可以轻松自如地应对各项数据"),
          icon: require("../../assets/Images/rzsj.png"),
        },
        {
          index: 5,
          name: this.$t("助力企业决策"),
          detail:
            this.$t("利用直观灵活多样的图表展示为企业提供业务驱动力，为企业提供决策支持。"),
          icon: require("../../assets/Images/qyjc.png"),
        },
        {
          index: 6,
          name: this.$t("多种统计方式"),
          detail:
            this.$t("求和、平均值、最大值、最小值、计数、不重复计数等多种统计方式，并可以对统计结果做数值格式处理"),
          icon: require("../../assets/Images/tjfs.png"),
        },
        {
          index: 7,
          name: this.$t("共享数据便捷"),
          detail: this.$t("台式机、笔记本电脑的本地单一接口"),
          icon: require("../../assets/Images/sjbj.png"),
        },
        {
          index: 8,
          name: this.$t("响应快速"),
          detail:
            this.$t("支持动态局部刷新，秒级响应，各项操作流畅，即使数量巨大，用户也不必担心卡顿"),
          icon: require("../../assets/Images/xyks.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.productAdv= [
        {
          index: 1,
          name: this.$t("提高决策效率"),
          detail:
            this.$t("实时全面的数据交互分析，一目了然的业务数据展示，高效的团队分享协作"),
          icon: require("../../assets/Images/jcxl.png"),
        },
        {
          index: 2,
          name: this.$t("丰富的可视化组件"),
          detail:
            this.$t("提供丰富的统计分析图表组件、Web组态图、地图等，以及行业特色化的场景仪表盘，满足各类数据展现需求"),
          icon: require("../../assets/Images/kshzj.png"),
        },
        {
          index: 3,
          name: this.$t("丰富的应用场景"),
          detail:
            this.$t("驾驶舱、宇宙、机场航班等场景，支持发布到多场景的数据大屏上进行可视化集中管控"),
          icon: require("../../assets/Images/yycj.png"),
        },
        {
          index: 4,
          name: this.$t("轻松认知数据"),
          detail:
            this.$t("数据双向互动，提供给用户对大数据可视化需求的无限助力与可能，让非专业用户也可以轻松自如地应对各项数据"),
          icon: require("../../assets/Images/rzsj.png"),
        },
        {
          index: 5,
          name: this.$t("助力企业决策"),
          detail:
            this.$t("利用直观灵活多样的图表展示为企业提供业务驱动力，为企业提供决策支持。"),
          icon: require("../../assets/Images/qyjc.png"),
        },
        {
          index: 6,
          name: this.$t("多种统计方式"),
          detail:
            this.$t("求和、平均值、最大值、最小值、计数、不重复计数等多种统计方式，并可以对统计结果做数值格式处理"),
          icon: require("../../assets/Images/tjfs.png"),
        },
        {
          index: 7,
          name: this.$t("共享数据便捷"),
          detail: this.$t("台式机、笔记本电脑的本地单一接口"),
          icon: require("../../assets/Images/sjbj.png"),
        },
        {
          index: 8,
          name: this.$t("响应快速"),
          detail:
            this.$t("支持动态局部刷新，秒级响应，各项操作流畅，即使数量巨大，用户也不必担心卡顿"),
          icon: require("../../assets/Images/xyks.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.zj_productAdvantages {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    margin-top: 6%;
    .line{
      background-image: url("../../assets/Images/line.png");
        background-position: 100% 38%;
    background-repeat: no-repeat;
    background-size: 0.8% 34%;
    }
    .adv_part {
      width: 25%;
      height: 50%;
      float: left;
      .pic {
        width: 100%;
        //height: 30%;
        img{
          width: 13%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        height: 50%;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
</style>
