<template>
  <!--新零售一体化系统-->
  <div class="zj_passIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/pos.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t("新零售一体化系统")}}</div>
        <div class="line3 font8rem black2">
          <p>
            {{$t("实现功能的集合，真正做到一体化，系统中包含了MDM、DRP、HR、POS、OA、WMS、CRM、FICO、EB以及一些传统接口功能，功能全面，解决了目前市场上其他家软件平台通过接口集成众多软件功能的问题。")}}
          </p>
          <p>
            {{$t("系统支持多语言，多财务组织、多HR组织结构图、多货币，较市面上软件逻辑处理更复杂。")}}
          </p>
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="case">
          <div class="rect1">
            <img src="../../assets/Images/xls/xls_rect2.png" />
          </div>
          <div class="rect2">
            <img src="../../assets/Images/xls/xls_rect2.png" />
          </div>
          <div class="rect3">
            <img src="../../assets/Images/xls/xls_rect1.png" />
          </div>
          <div class="rect4">
            <img src="../../assets/Images/xls/xls_rect1.png" />
          </div>
          <div class="rect5">
            <img src="../../assets/Images/xls/xls_rect1.png" />
          </div>
          <div class="rect6">
            <img src="../../assets/Images/xls/xls_rect3.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passIntroduce",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passIntroduce {
  width: 100%;
  height: 100%;
  .zj_inside {
    width: 65%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        width: 100%;
        height: 15%;
        img {
          width: 8%;
        }
      }
      .line3 {
        width: 100%;
        height: 70%;
        line-height: 2.5rem;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .case {
        width: 100%;
        height: 100%;
        background-image: url("../../assets/Images/xls/xls_Pic.png");
        background-repeat: no-repeat;
        background-size: 100%;
          padding: 1px;
        @keyframes movepoint {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          40% {
            transform: translate(0, -40%);
            opacity: 1;
          }
          80% {
            transform: translate(0, -80%);
            opacity: 0.6;
          }
          90% {
            transform: translate(0, -90%);
            opacity: 0.3;
          }
          100% {
            transform: translate(0, -100%);
            opacity: 0;
          }
        }
        
        @keyframes movepoint2 {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          40% {
            transform: translate(0, -60%);
            opacity: 1;
          }
          80% {
            transform: translate(0, -100%);
            opacity: 0.6;
          }
          90% {
            transform: translate(0, -110%);
            opacity: 0.3;
          }
          100% {
            transform: translate(0, -120%);
            opacity: 0;
          }
        }
        @keyframes movepoint3 {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          60% {
            transform: translate(120%, -90%);
            opacity: 1;
          }
          100% {
            transform: translate(200%, -150%);
            opacity: 0;
          }
        }
        
        @keyframes movepoint4 {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          70% {
            transform: translate(135%, -105%);
            opacity: 0;
          }
          100% {
            transform: translate(200%, -150%);
            opacity: 0;
          }
        }
        @keyframes movepoint5 {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          70% {
            transform: translate(135%, -105%);
            opacity: 1;
          }
          80% {
            transform: translate(155%, -120%);
            opacity: 0;
          }
          100% {
            transform: translate(200%, -150%);
            opacity: 0;
          }
        }
        @keyframes movepoint6 {
          0% {
            transform: translate(0, 0%);
            opacity: 1;
          }
          // 40% {
          //   transform: translate(-15%, -10%);
          //   opacity: 1;
          // }
          // 80% {
          //   transform: translate(-40%, -20%);
          //   opacity: 1;
          // }
          100% {
            transform: translate(-300%, -230%);
            opacity: 0;
          }
        }
        .rect1 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 60%;
          margin-top: 33%;
          animation: movepoint 4s infinite linear;
          -webkit-animation: movepoint 4s infinite linear;
          img {
            width: 60%;
          }
        }
        .rect2 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 73%;
          margin-top: -18%;
          animation: movepoint2 3s infinite linear;
          -webkit-animation: movepoint2 3s infinite linear;
          img {
            width: 50%;
          }
        }
        .rect3 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 12%;
          margin-top: 20%;
          animation: movepoint3 3s infinite linear;
          -webkit-animation: movepoint3 3s infinite linear;
          img {
            width: 50%;
          }
        }
        .rect4 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 20%;
          margin-top: -6%;
          animation: movepoint4 2s infinite linear;
          -webkit-animation: movepoint4 2s infinite linear;
          img {
            width: 50%;
          }
        }
        .rect5 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 62%;
          margin-top: 11%;
          animation: movepoint5 3s infinite linear;
          -webkit-animation: movepoint5 3s infinite linear;
          img {
            width: 50%;
          }
        }
        .rect6 {
          width: 10%;
          height: 10%;
          position: relative;
          margin-left: 50%;
          margin-top: -19%;
          animation: movepoint6 3s infinite linear;
          -webkit-animation: movepoint6 3s infinite linear;
          img {
            width: 50%;
          }
        }
      }
    }
  }
}
</style>
