<template>
  <!--助企业真正实现一体化管理-->
  <div class="zj_xlsProducts">
    <div class="xlcname cnname font16rem black flexcc wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("助企业真正实现一体化管理")}}</div>
    <div class="xlsTab wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="weui_tab flexcc flexlrbetween">
        <div
          class="xls_tab"
          :class="{ ui_tab_item_on: item.name == selected }"
          v-for="item in xlsTabdata"
          :key="item.index"
          @click="toProduct(item.name)"
        >
          <div class="pic flexcc pic1 hidden">
            <img :src="item.icon1" style="width:2rem" />
          </div>
          <div class="pic flexcc pic2">
            <img :src="item.icon2" style="width:2rem" />
          </div>
          <div class="name flexcc">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="xlsProducts_tab" v-if="selected == 'DRP'">
      <div class="DRP">
        <DRP :drpData="drpData" :selected="selected"></DRP>
      </div>
    </div>
    <div class="xlsProducts_tab" v-if="selected == 'EB'">
      <div class="DRP">
        <DRP :drpData="ebData" :selected="selected"></DRP>
      </div>
      <div class="EB">
        <EB :selected="selected"></EB>
      </div>
    </div>
    <div class="xlsProducts_tab" v-if="selected == 'MDM'">
      <div class="MDM">
        <MDM :proData="mdmData" :selected="selected"></MDM>
      </div>
    </div>
    <div class="xlsProducts_tab" v-if="selected == 'WMS'">
      <div class="WMS">
        <WMS :proData="wmsData"></WMS>
      </div>
    </div>
     <div class="xlsProducts_tab" v-if="selected == 'CRM'">
      <div class="CRM">
        <CRM :proData="crmData" :selected="selected"></CRM>
      </div>
    </div>
    <div class="xlsProducts_tab" v-if="selected == 'FICO'">
      <div class="FCIO">
        <FCIO :proData="fcioData"></FCIO>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
import DRP from "@/components/product/DRP.vue";
import EB from "@/components/product/EB.vue";
import MDM from "@/components/product/MDM.vue";
import WMS from "@/components/product/WMS.vue";
import CRM from "@/components/product/CRM.vue";
import FCIO from "@/components/product/FCIO.vue";
export default {
  name: "zj_xlsProducts",
  data() {
    return {
      flg: 0,
      i:0,
      xlsTabdata: [
        {
          index: 1,
          name: "DRP",
          icon1: require("../../assets/Images/xls/xls_drpicon.png"),
          icon2: require("../../assets/Images/xls/xls_drpicon2.png"),
        },
        {
          index: 2,
          name: "EB",
          icon1: require("../../assets/Images/xls/xls_ebicon.png"),
          icon2: require("../../assets/Images/xls/xls_ebicon2.png"),
        },
        {
          index: 3,
          name: "MDM",
          icon1: require("../../assets/Images/xls/xls_mdmicon.png"),
          icon2: require("../../assets/Images/xls/xls_mdmicon2.png"),
        },
        {
          index: 4,
          name: "WMS",
          icon1: require("../../assets/Images/xls/xls_wmsicon.png"),
          icon2: require("../../assets/Images/xls/xls_wmsicon2.png"),
        },
        {
          index: 5,
          name: "CRM",
          icon1: require("../../assets/Images/xls/xls_crmicon.png"),
          icon2: require("../../assets/Images/xls/xls_crmicon2.png"),
        },
        {
          index: 6,
          name: "FICO",
          icon1: require("../../assets/Images/xls/xls_fcioicon.png"),
          icon2: require("../../assets/Images/xls/xls_fcioicon2.png"),
        },
      ],
      selected: "DRP",
      drp_pic: require("../../assets/Images/xls/drp_pic.png"),
      eb_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
      drpData: {
        name: this.$t("魔元术新零售一体化系统-DRP"),
        id: "DRP",
        drp_pic: require("../../assets/Images/xls/drp_pic.png"),
        icon1: require("../../assets/Images/xls/xls_drpicon.png"),
        value1:
          this.$t("行业定制的门店巡店(IIS)、神秘访客(MSP)及日常管理评分，是在10多年零售行业及上百家零售业态中提炼出的管理规范。"),
        value2:
          this.$t("门店支持PC端和手机端，结合系统软件及移动设备，全方面的提示传统管理模式，做到事半功倍。"),
        value3: this.$t("可多个二维报表展示在一个页面上。"),
      },
      ebData: {
        name: this.$t("魔元术新零售一体化系统-EB"),
        id: "EB",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_ebicon.png"),
        value1:
          this.$t("EB模块集合了电商、正向O2O,反向O2O，云仓等业务，是一整套 OMS 的订单中台，并且与POS前台紧密结合，实现库存共享和最佳发货路由。"),
        value2: this.$t("一体化系统能更加保障库存数据的实效性。"),
        value3: "",
      },
       mdmData: {
        name: this.$t("魔元术新零售一体化系统-MDM"),
        id: "MDM",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_mdmicon.png"),
        value1:
          this.$t("支持多品牌事业部、多品牌商品运营、多品牌渠道管理、多品牌核算等"),
        value2: this.$t("系统拥有400多个可设置开关型功能参数，灵活调整，打通人、货、店、公司，实现参数化，可自由配置。"),
        value3: "",
      },
       wmsData: {
        name: this.$t("魔元术新零售一体化系统-WMS"),
        id: "WMS",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_wmsicon.png"),
        value1:this.$t("支持仓库锁定商品可下单数量、装车单纳入WMS管理、差异仓库管理、多货主多建筑体多类型多库区化库存共享管理、用户自定义组合拣货配置模式。"),
        value2: "",
        value3: "",
      },
       crmData: {
        name: this.$t("魔元术新零售一体化系统-CRM"),
        id: "CRM",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_crmicon.png"),
        value1:this.$t("拥有用户画像管理、专属导购功能，丰富的自定义活动入会员积分兑换现金，现金翻倍；自定义发券规则。"),
        value2: "",
        value3: "",
      },
       fcioData: {
        name: this.$t("魔元术新零售一体化系统-FCIO"),
        id: "FICO",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_fcioicon.png"),
        value1:this.$t("支持多财务组织、多货币、多种成本管理模式。"),
        value2: this.$t("财务应收应付与前端业务紧密结合，深入业务的每一个环节，供应商、加盟商的对账，在线协调平台，实现内外财务数据共享。"),
        value3: this.$t("集成税务接口,通过商场开票模块可直接上传发票信息。"),
      },
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.drpData= {
        name: this.$t("魔元术新零售一体化系统-DRP"),
        id: "DRP",
        drp_pic: require("../../assets/Images/xls/drp_pic.png"),
        icon1: require("../../assets/Images/xls/xls_drpicon.png"),
        value1:
          this.$t("行业定制的门店巡店(IIS)、神秘访客(MSP)及日常管理评分，是在10多年零售行业及上百家零售业态中提炼出的管理规范。"),
        value2:
          this.$t("门店支持PC端和手机端，结合系统软件及移动设备，全方面的提示传统管理模式，做到事半功倍。"),
        value3: this.$t("可多个二维报表展示在一个页面上。"),
      }
      this.ebData = {
        name: this.$t("魔元术新零售一体化系统-EB"),
        id: "EB",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_ebicon.png"),
        value1:
          this.$t("EB模块集合了电商、正向O2O,反向O2O，云仓等业务，是一整套 OMS 的订单中台，并且与POS前台紧密结合，实现库存共享和最佳发货路由。"),
        value2: this.$t("一体化系统能更加保障库存数据的实效性。"),
        value3: "",
      }
      this.mdmData = {
        name: this.$t("魔元术新零售一体化系统-MDM"),
        id: "MDM",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_mdmicon.png"),
        value1:
          this.$t("支持多品牌事业部、多品牌商品运营、多品牌渠道管理、多品牌核算等"),
        value2: this.$t("系统拥有400多个可设置开关型功能参数，灵活调整，打通人、货、店、公司，实现参数化，可自由配置。"),
        value3: "",
      }
      this.wmsData = {
        name: this.$t("魔元术新零售一体化系统-WMS"),
        id: "WMS",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_wmsicon.png"),
        value1:this.$t("支持仓库锁定商品可下单数量、装车单纳入WMS管理、差异仓库管理、多货主多建筑体多类型多库区化库存共享管理、用户自定义组合拣货配置模式。"),
        value2: "",
        value3: "",
      }
      this.crmData = {
        name: this.$t("魔元术新零售一体化系统-CRM"),
        id: "CRM",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_crmicon.png"),
        value1:this.$t("拥有用户画像管理、专属导购功能，丰富的自定义活动入会员积分兑换现金，现金翻倍；自定义发券规则。"),
        value2: "",
        value3: "",
      }
      this.fcioData = {
        name: this.$t("魔元术新零售一体化系统-FCIO"),
        id: "FICO",
        drp_pic: require("../../assets/Images/xls/eb/eb_pic.png"),
        icon1: require("../../assets/Images/xls/xls_fcioicon.png"),
        value1:this.$t("支持多财务组织、多货币、多种成本管理模式。"),
        value2: this.$t("财务应收应付与前端业务紧密结合，深入业务的每一个环节，供应商、加盟商的对账，在线协调平台，实现内外财务数据共享。"),
        value3: this.$t("集成税务接口,通过商场开票模块可直接上传发票信息。"),
      }
   }
  },
  mounted() {
    this.changexlsTab();
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
    changexlsTab() {
      $(".ui_tab_item_on .pic2").addClass("hidden");
      $(".ui_tab_item_on .pic1").removeClass("hidden");
      $(".xls_tab").click(function () {
        $(this)
          .addClass("ui_tab_item_on")
          .siblings()
          .removeClass("ui_tab_item_on");
        $(".pic2").removeClass("hidden");
        $(".pic1").addClass("hidden");
        $(".ui_tab_item_on .pic2").addClass("hidden");
        $(".ui_tab_item_on .pic1").removeClass("hidden");
      });
    },
    toProduct(name) {
      this.i++;
      console.log(name);
      this.selected = name;
      if(this.i>0){
        $(".xlsTab").removeClass("wow");
        $(".xlcname").removeClass("wow");
      }else{
        $(".xlsTab").addClass("wow");
        $(".xlcname").addClass("wow");
      }
      // this.$emit("fatherMethod", time);
    },
  },
  components: {
    DRP,
    EB,
    MDM,
    WMS,
    CRM,
    FCIO
  },
};
</script>

<style scoped lang="scss">
.zj_xlsProducts {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 4rem;
  }
  .xlsTab {
    width: 100%;
    height: 10%;
    margin-bottom: 2rem;
    .weui_tab {
      width: 100%;
      height: 100%;
      .ui_tab_item_on {
        color: #2a85f2;
      }
      .ui_tab_area {
        width: 16%;
        height: 100%;
        .pic {
          width: 100%;
          height: 50%;
          img{
            width: 5%;
          }
        }
        .name {
          width: 100%;
          height: 50%;
        }
      }
    }
  }
  .xlsProducts_tab {
    width: 100%;
    //height: 30rem;
    margin: 0 auto;
    display: block;
    .DRP {
      width: 100%;
      height: 30rem;
    }
    .EB {
      width: 100%;
      height: 40rem;
    }
    .MDM{
      width: 100%;
      height: 30rem;
    }
    .WMS{
      width: 100%;
      height: 30rem;
    }
    .CRM{
      width: 100%;
      height: 30rem;
    }
    .FCIO{
      width: 100%;
      height: 30rem;
    }
  }
}
</style>
